<template>
  <section ref="testimonials" class="testimonials">

    <div id="quote-1" class="card card-img">
      <div class="img-container">
        <img src="../assets/img/quote-placeholder-img.jpg" alt="Quote Placeholder Image">
      </div>
      <div class="content">
        <span class="description">
          "The safety features are amazing. It pulls you back in the lane when you veer to the side<span id="technology-3" class="asterisk">*</span>."<br>
        </span>
        <span class="author">Heidi, <br> 2019 Chrysler Pacifica Hybrid Owner</span>
      </div>
    </div>

    <div id="quote-2" class="card card-quote">
      <span class="quote">
        My mom asked, “What do I need to do? What’s special?” I answer, "Nothing! Just get in and press the go button. That’s it."
      </span>
      <span class="author">Matthew, <br> 2021 Chrysler Pacifica Hybrid Owner</span>
    </div>

    <div id="img-1" class="card card-img">
      <div class="img-container">
        <img src="../assets/img/technology/testimonial-technology.jpg" alt="2021 Chrysler Pacifica Hybrid - Cluster.">
      </div>
    </div>

    <div id="stat-1" class="card card-basic">
      <h3 class="heading">Chrysler Pacifica Hybrid Dash and App<span id="technology-0" class="asterisk">*</span></h3>
      <span class="subheading">- Check range <br> – Set charging schedule <br> – Review drive history <br> – Get charge status updates <br> – Find charging stations</span>
    </div>

    <!-- <div id="stat-2" class="card card-basic">
      <span class="heading">Regenerative braking</span>
      <span class="subheading">Converts kinetic energy to electricity when braking.</span>
    </div> -->

    <div id="stat-4" class="card card-basic">
      <h3 class="heading">5+ million hybrids on US roads<span id="technology-1" class="asterisk d-inline">*</span></h3>
      <span class="subheading">(And about that many drivers who love them)</span>
    </div> 

    <div id="stat-5" class="card card-basic">
      <h3 class="heading">The only difference is the dash</h3>
      <span class="subheading">Starts, stops and runs just like a gas-powered Chrysler Pacifica.</span>
    </div>

    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 1">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 2">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 3">

  </section>
</template>

<script>
export default {
  name: 'TestimonialsTechnology',
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  }
}
</script>