<template>
  <div class="overlay-nav overlay-facts" :class="isActive ? 'active' : null" @click="emitClose()">
    <div class="overlay-inner">
      <div class="overlay-header">
        <h5 class="overlay-title md-title">Welcome to the Chrysler Pacifica Hybrid <br> Fact Finder!</h5>
        <h5 class="overlay-title sm-title">Welcome to <br> the Chrysler Pacifica Hybrid Fact Finder!</h5>
        <h6 class="overlay-subtitle">Select the topic you want to explore.</h6>
      </div>
      <div class="facts-grid" :class="isActive ? 'active' : null">
        <div class="grid-item" v-for="item in items" :key="item.id">
          <!-- <button @click="navigateAndClose(item.href); track('Click', item.eventCategoryNAVFinder, 'Navigation Fact Finder')" :class="$route.name == item.component ? 'active' : null" class="trackable" :id="item.iconFont">
            <img :src="getIcon(item.iconLg)" alt="Fact Icon"> 
            <span>{{ item.name }}</span>
          </button> -->
          <a :href="item.href" @click="navigateAndClose(item.href); track('Click', item.eventCategoryNAVFinder, 'Navigation Fact Finder')" :class="$route.name == item.component ? 'active' : null" class="trackable" :id="item.iconFont">
            <img :src="getIcon(item.iconLg)" alt="Fact Icon"> 
            <span>{{ item.name }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Overlay nav for mobile
export default {
  name: 'OverlayFacts',
  props: {
    items: null,
    isActive: Boolean
  },
  methods: {
    emitClose() {
      this.$emit('active', false);
    },
    navigateAndClose() {
      this.emitClose();
      // this.$router.push({ path: route });
      localStorage.setItem('overlay', false);
      //window.location.reload();
    },
    getIcon(icon) {
      return icon ? require(`../assets/img/icons/${icon}`) : ''
    },
    track(action, category, label) {
      this.$gtag.event(action, {
      'event_category': category,
      'event_label': label,
    });
    }
  }
}
</script>