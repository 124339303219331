<template>
  <div id="faq">

    <div class="hero hero-home hero-myth hero-basic">
      <div class="hero-inner">
        <div class="container-fluid">
          <div class="row align-items-md-center">
            <div class="col-12 col-xl-7 fade-up">
              <h1 class="hero-heading heading-light fade-up mb-3">Frequently Asked Questions</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-accent"><img class="w-100" src="../assets/img/bg-repeat.png" alt="background accent image"></div>
    </div>

    <div class="content">
      <div class="container-fluid">
        
        <section v-for="(faq, index) in faqs" :key="`${faq.slug}-${index}`" :id="faq.slug">
          <div class="row">
            <div class="col-sm-12 section-heading">
              <h2 class="heading">{{ faq.name }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="accordion" :id="`accordion-faq-${faq.slug}`">
                
                <div v-for="(item, index) in faq.items" :key="`${faq.name}-question-${index}`" class="card">
                  <div class="card-header" :id="`${faq.slug}-heading-${index}`">
                    <button :id="`${faq.slug}-trigger-${index}`" class="btn btn-link btn-block text-left trackable" type="button" data-toggle="collapse" :data-target="`#${faq.slug}-collapse-${index}`" :aria-expanded="index === 0 ? 'true' : 'false'" :aria-controls="`${faq.slug}-collapse-${index}`" v-html="item.question" @click="track('Click', item.question, 'FAQ Expand /Close')">
                      Question
                    </button>
                  </div>

                  <div :id="`${faq.slug}-collapse-${index}`" class="collapse" :class="index === 0 ? 'show' : null" :aria-labelledby="`${faq.slug}-trigger-${index}`" :data-parent="`#accordion-faq-${faq.slug}`">
                    <div class="card-body">
                      <p v-for="(answer, index) in item.answer" :key="`${faq.slug}-answer-${index}`" v-html="answer">Answer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Faq',
  data: () => {
    return {
      faqs: null,
      loading: true
    }
  },
  metaInfo: {
    title: 'FAQ | Why Chrysler Pacifica Hybrid',
    meta: [
      { name: 'description', content: 'Chrysler Pacifica Hybrid (PHEV) frequently asked questions' },
      { name: 'keywords', content: 'Frequently Asked Questions, FAQs, Chrysler Pacifica Hybrid' },
      { name: 'og:description', content: 'Chrysler Pacifica Hybrid (PHEV) frequently asked questions' },
      { name: 'og:image', content: '/img/hero-lg.abca891b.jpg' }
    ]
  },
  mounted() {
    axios.get(`/data/faq.json`).then(response => {    
      this.faqs = response.data[0].faqs
      this.loading = false
      this.$gtag.pageview(this.$route);
    }).then(() => {
      setTimeout(function() {
        const linkCollapseForthElement = document.querySelector('#links-collapse ul li:nth-child(4)');
        const linkCollapseFifthElement = document.querySelector('#links-collapse ul li:nth-child(5)');

        if (linkCollapseForthElement) {
          linkCollapseForthElement.style.display = 'none';
        }

        if (linkCollapseFifthElement) {
          linkCollapseFifthElement.style.display = 'none';
        }
      }, 500)
    });
  },
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
  }
}
</script>