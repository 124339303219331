<template>
  <section ref="testimonials" class="testimonials">
    <div id="video-2" class="card card-video">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe id="player2" class="embed-responsive-item" src="https://www.youtube.com/embed/phI4tKnYYW0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="content">
        <h3 class="heading text-primary">"I can change the configuration of the seats so many different ways."</h3>
        <p>2018 Chrysler Pacifica Hybrid owner Angela demonstrates how to use the vehicle's third row Stow 'N Go feature.</p>
      </div>
    </div>
    
    <div id="quote-1" class="card card-quote">
      <span class="quote">
        It’s amazing. We took the seats out. Folded the 3rd row down and used it as a cargo van.<br>
      </span>
      <span class="author">Matthew, <br> 2021 Chrysler Pacifica Hybrid Owner</span>
    </div>

    <div id="img-1" class="card card-img">
      <div class="img-container"> 
        <img src="../assets/img/practicality/testimonial-practicality.jpg" alt="2021 Chrysler Pacifica Hybrid - Top down view with roof removed to see seating configurations.">
      </div>
    </div>
    <div id="img-2" class="card card-img">
      <div class="img-container"> 
        <img src="../assets/img/practicality/testimonial-practicality-customer.jpg" alt="2021 Chrysler Pacifica Hybrid - Top down view with roof removed to see seating configurations.">
      </div>
      <div>
        <div class="content">
          <span class="quote">
            “I was bragging so much about my Pacifica Hybrid that a coworker now has the exact same car as me. She asked me
            if I really liked it and I said what’s there not to like? It uses less gas and has more room than the other
            vehicles I looked at. And features… all the features, between the cameras, the sunroof, the shades on the
            windows, the rear seat entertainment screens on the trim level I got … I mean, my kids are quite happy in
            the car.”<br>
          </span>
          <span class="author">
            Brena,<br>
            2017 Chrysler Pacifica Hybrid Owner
          </span>
        </div>
      </div>
     
    </div>

    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 1">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 2">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 3">
  </section>
</template>

<script>
export default {
  name: 'TestimonialsPracticality',
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  }
}
</script>