
<template>
  <div>
    <div class="modal modal-primary fade" id="chat-modal" tabindex="-1" aria-labelledby="chat-modal-heading" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button class="btn btn-close btn-icon" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            
            <h5 class="modal-title" id="chat-modal-heading-online" style="line-height: 1.2;">Live representatives are <br class="d-none d-lg-block" /> plugged in and ready to respond!</h5>
            <h5 class="modal-title" id="chat-modal-heading-offline">We're recharging our batteries</h5>
            <h5 class="modal-title" id="chat-modal-heading-holiday" style="line-height: 1.2;">Our team is currently enjoying their holiday, <br class="d-none d-lg-block" /> and we hope you are, too</h5>
            
            <h6 class="modal-subtitle" id="chat-sub-hd-open">We're plugged in Monday – Friday, <br> 11:00 a.m. – 8:00 p.m. EST (8:00 a.m. – 5:00 p.m. PST)</h6>
            <h6 class="modal-subtitle" id="chat-sub-hd-holiday">We'll be back online on <span id="working-date-after-holiday"></span>, <br> 11:00 a.m. – 8:00 p.m. EST (8:00 a.m. – 5:00 p.m. PST)</h6>

            <h6 id="message-online" class="modal-subtitle w-100">
              If you have a question, please start a chat, call us, or send an email. Real, human experts are on the other end of our phone, chat and email and look forward to speaking with you. Talk to us now!
            </h6>
            <h6 id="message-offline" class="modal-subtitle w-100">
              If you'd like to reach out to us now, please do so via call or email.
            </h6>
            <h6 id="message-holiday" class="modal-subtitle w-100">
              In the meantime, please send us an email or leave a voicemail and you'll hear back from us on the next business day.
            </h6>

            <ul class="nav nav-tabs mt-3 mt-md-4" id="contact-tab-nav" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="btn btn-icon btn-gradient-success text-uppercase trackable" id="chat-tab" href="#" data-dismiss="modal" onclick="javascript:$zoho.salesiq.floatwindow.visible('show');" @click="track('Click', 'Start Chat', 'Contact Expert')"><i class="fa fa-comments-o"></i> <span class="d-block">Start Chat</span></a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="btn btn-secondary btn-icon text-uppercase trackable" id="phone-tab" data-toggle="tab" href="#phone" role="tab" aria-controls="phone" aria-selected="false" @click="track('Click', 'Phone', 'Contact Expert')"><i class="fa fa-phone"></i> Call</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="btn btn-secondary btn-icon text-uppercase trackable" id="email-tab" data-toggle="tab" href="#email" role="tab" aria-controls="email" aria-selected="false" @click="track('Click', 'Email', 'Contact Expert')"><i class="fa fa-envelope"></i> Email</a>
              </li>
            </ul>
          </div>
          <div class="modal-body">
            <div class="tab-content" id="contact-tabs">
              <div class="tab-pane fade show" id="phone" role="tabpanel" aria-labelledby="phone-tab">
                <p class="tab-heading"><a href="tel:8668434760" target="_blank" rel="noopener">866-843-4760</a></p>
              </div>
              <div class="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email-tab">
                <p class="tab-heading"><a href="mailto:support@whypacificahybrid.zohodesk.com?subject=Pacifica%20Hybrid%20Question" target="_blank" rel="noopener">support@whypacificahybrid.zohodesk.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from 'moment-timezone'
import JQuery from 'jquery'
let $ = JQuery

export default {
  name: 'ChatModal',
  data() {
    return {
      today: moment().tz('America/New_York'),
      chatOpen: false
    }
  },
  mounted() {
    // Reset existing tabs when modal opens
    $('#chat-modal').on('show.bs.modal', function() {
      $('.nav-tabs .nav-item [data-toggle="tab"]').removeClass('active').attr('aria-selected', false);
      $('#contact-tabs .tab-pane').removeClass('show active');
    })

    //this.businessHours()
  },
  methods: {
    // businessHours() {
    //   // check if we're within business hours
    //   if ((this.today.day() == 0 || this.today.day() == 6) || (this.today.hours() < 11 || this.today.hours() > 20)) {
    //     this.chatOpen = false

    //     let chats = document.querySelectorAll('.zsiq_floatmain')
    //     chats.forEach(chat => {
    //       chat.style.visibility = 'hidden !important'
    //       chat.style.display = 'none !important'
    //     })
    //   } else { this.chatOpen = true }
    // },
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  }
}
</script>