<template>
  <div id="site-map">

    <div class="hero hero-home hero-myth hero-basic">
      <div class="hero-inner">
        <div class="container-fluid">
          <div class="row align-items-md-center">
            <div class="col-12 col-xl-7 fade-up">
              <h1 class="hero-heading heading-light fade-up mb-3">Sitemap</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-accent"><img class="w-100" src="../assets/img/bg-repeat.png" alt="background accent image"></div>
    </div>

    <div class="content" :class="{'active' : !loading}">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="entry-block-container">
              <div class="entry-block" v-for="(page, index) in pages" :key="`${page.slug}-${index}`" :class="{'child' : page.inner === true}">
                <h2 class="entry-title"><a :href="page.url" :id="`${page.slug}-entry`"  @click="track('Click', 'Sitemap', `Entry - ${page.name}`)">{{ page.name }}</a></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Site_Map',
  metaInfo: {
    title: 'Site Map | Why Chrysler Pacifica Hybrid',
    meta: [
      { name: 'description', content: 'Chrysler Pacifica Hybrid (PHEV) sitemap' },
      { name: 'keywords', content: 'Sitemap, Chrysler Pacifica Hybrid' },
      { name: 'og:description', content: 'Chrysler Pacifica Hybrid (PHEV) sitemap' },
      { name: 'og:image', content: '/img/hero-lg.abca891b.jpg' }
    ]
  },
  data() {
    return {
      loading: true,
      pages: [
        {name: 'Home', slug: 'home', url: `${process.env.VUE_APP_DOMAIN}/`, inner: false}, 
        {name: 'Range', slug: 'range', url: `${process.env.VUE_APP_DOMAIN}/range/`, inner: true},
        {name: 'Charging', slugh: 'charging', url: `${process.env.VUE_APP_DOMAIN}/charging/`, inner: true},
        {name: 'Affordability', slug: 'affordability', url: `${process.env.VUE_APP_DOMAIN}/affordability/`, inner: true},
        {name: 'Battery Life', slug:'battery-life', url: `${process.env.VUE_APP_DOMAIN}/battery-life/`, inner: true},
        {name: 'Technology', slug: 'technology', url: `${process.env.VUE_APP_DOMAIN}/technology/`, inner: true},
        {name: 'Practicality', slug: 'pracitaclity', url: `${process.env.VUE_APP_DOMAIN}/practicality/`, inner: true},
        {name: 'FAQ', slug: 'faq', url: `${process.env.VUE_APP_DOMAIN}/faq/`, inner: false}
      ]
    }
  },
  mounted() {
    setTimeout(() => this.loading = false, 500);
  },
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
  }
}
</script>