import JQuery from 'jquery'
let $ = JQuery

$(function() {


  // Disclaimer functionality
  //-----
  
  // Disclaimer modal functionality
  $(document).on('click', '.asterisk', function() {
    
    // slice string to get tab name
    let cat = $(this).attr('id')
    let final = cat.split('-')[1];

    $('#disclaimer-modal #disclaimer-body p').each(function(index) {
      $(this).removeClass('active');
      $(this).show();

      if (Number(final) != index) {
        $(this).hide();
      }
    });

    $('#disclaimer-modal').addClass('header-condensed');

    $('#disclaimer-modal').modal('show');

    // this.$gtag.event('Click', {
    //   event_category: 'Disclaimers',
    //   event_label: location.href + ' - ' + final,
    // });

  });

  // Close disclaimer modal
  $(document).on('click', '[data-target="#disclaimer-modal"]', function() {
    $('#disclaimer-modal #disclaimer-body p').show();
    $('#disclaimer-modal').removeClass('header-condensed');
  });

  
  
  // Zoho functionality
  //-----

  // Zoho chat to close contact modal if open
  $(document).on('click', '#zsiq_agtpic', function() {
    $('#chat-modal').modal('hide');
  });

})

