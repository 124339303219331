
<template>
  <div v-if="!loading">
    <div class="modal modal-primary fade" id="disclaimer-modal" ref="disclaimerModal" tabindex="-1" aria-labelledby="chat-modal-heading" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-3">
            <button class="btn btn-close btn-icon" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            <h5 class="modal-title condensed" id="chat-modal-heading">Disclaimers</h5>
            <img class="mh-leaves" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image">
          </div>
          <div id="disclaimer-body" class="modal-body">
            <p v-for="(disclaimer, index) in items[`${currentRoute}`]" :key="`${disclaimer}-disclaimer-${index}`">
              <span class="font-bold" v-html="disclaimer.ref + ':'"></span> <span v-html="disclaimer.desc"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'

export default {
  name: 'DisclaimerModal',
  props: ['currentRoute'],
  data: () => {
    return {
      items: null,
      loading: true
    }
  },
  mounted() {
    axios.get(`/data/disclaimers.json`).then(response => {
      this.items = response.data[0];
      this.loading = false;
    })
  }
}
</script>