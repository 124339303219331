<template>
  <div id="practicality" class="bg-light">
    <div class="hero hero-myth">
      <picture>
        <source
          media="(min-width: 1200px)"
          srcset="../assets/img/practicality/hero-lg.jpg"
        />
        <source
          media="(min-width:767px)"
          srcset="../assets/img/practicality/hero-md.jpg"
        />
        <img
          src="../assets/img/practicality/hero-xs.jpg"
          alt="Chrysler Pacifica Hybrid - Practicality Facts"
        />
      </picture>
      <div class="title">
        <img src="../assets/img/icons/full/icon-practicality.png" alt="Title Icon" />
        <h1 class="myth-title">Practicality</h1>
      </div>
    </div>

    <section class="intro py-5">
      <div class="container-fluid">
        <div class="row">
          <div class="fact col-lg-12 text-center">
            <span class="fact-label">Fact</span>
            <h2 class="fact-heading">
              The Chrysler Pacifica Hybrid is the most versatile and spacious
              plug-in hybrid in America<span id="prac-1" class="asterisk">*</span>.
            </h2>
          </div>
        </div>
      </div>
    </section>

    <section
      id="polaroid-img-txt-container"
      class="animation first"
      @mouseover="
        track('Scroll', 'On-site engagement', 'Practicality - Scroll')
      "
    >
      <div class="container-fluid animation-inner">
        <div class="facts-scroller">
          <div class="stats-container">
            <div class="stats stats-1">
              <span class="main-headline">America's First and Only</span>

              <div class="stat-container">
                <span class="stat stat-1"
                  >Chrysler Pacifica Hybrid is the first and only plug-in hybrid
                  minivan
                </span>
              </div>
            </div>

            <div class="stats stats-2">
              <span class="main-headline">
                Ultimate Plug-in Hybrid for Families<span id="prac-2" class="asterisk d-inline">*</span>
              </span>

              <div class="stat-container">
                <span class="stat"
                  >Seats up to 7 and room for plenty of cargo</span>
              </div>
            </div>

            <div class="stats stats-3">
              <span class="main-headline">
                Up to 81 seating configurations<span
                  id="prac-0"
                  class="asterisk d-inline"
                  >*</span
                >
              </span>

              <div class="stat-container">
                <span class="stat"
                  >with removable seats in the second row and option to stow third-row seats into the floor
                </span>
              </div>
            </div>
          </div>

          <div class="graphic">
            <div class="images images-1">
              <img
                class="pol-img"
                src="../assets/img/practicality/PHEV_Polaroids_01.jpg"
                alt="Polaroid Image 1"
              />
            </div>
            <div class="images images-2">
              <img
                class="pol-img"
                src="../assets/img/practicality/PHEV_Polaroids_02.jpg"
                alt="Polaroid Image 2"
              />
            </div>
            <div class="images images-3">
              <img
                class="pol-img"
                src="../assets/img/practicality/PHEV_Polaroids_03.jpg"
                alt="Polaroid Image 3"
              />
            </div>
            <!-- <div class="images images-4">
              <img
                class="pol-img"
                src="../assets/img/practicality/PHEV_Polaroids_04.jpg"
                alt="Polaroid Image 4"
              />
            </div>
            <div class="images images-5">
              <img
                class="pol-img"
                src="../assets/img/practicality/PHEV_Polaroids_05.jpg"
                alt="Polaroid Image 5"
              />
            </div>
            <div class="images images-6">
              <img
                class="pol-img"
                src="../assets/img/practicality/PHEV_Polaroids_06.jpg"
                alt="Polaroid Image 6"
              />
            </div> -->
          </div>

          <div class="stats-sm-container">
            <div class="stats stats-1">
              <span class="main-headline">America's First and Only </span>

              <div class="stat-container">
                <span class="stat stat-1"
                  >Chrysler Pacifica Hybrid is the first and only plug-in hybrid
                  minivan
                </span>
              </div>
            </div>

            <div class="stats stats-2">
              <span class="main-headline">
                Ultimate Plug-in Hybrid for Families<span id="pracb-2" class="asterisk d-inline">*</span>
              </span>

              <div class="stat-container">
                <span class="stat"
                  >Seats up to 7 and room for plenty of cargo
                </span>
              </div>
            </div>

            <div class="stats stats-3">
              <span class="main-headline">
                Up to 81 seating configurations<span
                  id="pracm-0"
                  class="asterisk d-inline"
                  >*</span
                >
              </span>

              <div class="stat-container">
                <span class="stat"
                  >with removable seats in the second row and option to stow third-row seats into the floor
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <PreFooter />

    <TestimonialsPracticality />
  </div>
</template>

<script>
import PreFooter from "@/components/PreFooter";
import TestimonialsPracticality from "@/components/TestimonialsPracticality";
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Practicality",
  components: { PreFooter, TestimonialsPracticality },
  data: () => {
    return {
      loading: true,
      tl: null
    };
  },
  metaInfo: {
    title: 'Practicality | Why Chrysler Pacifica Hybrid',
    meta: [
      { name: 'description', content: 'Chrysler Pacifica Hybrid is America’s Largest Hybrid.' },
      { name: 'keywords', content: 'Some, keywords, for, testing' },
      { name: 'og:description', content: 'Chrysler Pacifica Hybrid is America’s Largest Hybrid.' },
      { name: 'og:image', content: '/img/hero-lg.9e6c350c.jpg' }
    ]
  },
  mounted() {
    this.loading = false;
    this.$gtag.pageview(this.$route);

    // Set animation
    this.tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".animation",
        pin: true,
        start: "center center",
        end: "+=" + window.innerHeight * 2,
        scrub: true
      },
    });

    this.tl
      //.to('.facts-scroller', {top: "20%", duration: 1}) // scroll element to center of scene

      .to(
        ".stats-1",
        { y: 0, opacity: 1, display: "block", duration: 2, delay: 2 },
        "img0"
      ) // slide in first headline
      //.to('.images-1', {x: -400, duration: 5, delay: 3}, 'img0') // flip graphic to second

      .to(".stats-1", { opacity: 1, display: "block", duration: 2, delay: 2 }) // slide in first headline

      .to(".stats-1", { opacity: 0, display: "none", duration: 0, delay: 2 }) // slide out first headline
      .to(".images-2", { x: 20, duration: 1 }, "img1") // flip graphic to third
      .to(
        ".stats-2",
        { y: 0, opacity: 1, display: "block", duration: 2 },
        "img1"
      ) // slide in second headline

      .to(".stats-2", { opacity: 0, display: "none", duration: 0, delay: 2 }) // slide out second headline
      .to(".images-3", { x: 40, duration: 2 }, "img2") // flip graphic to third
      .to(
        ".stats-3",
        { y: 0, opacity: 1, display: "block", duration: 2 },
        "img2"
      ) // slide in third headline

    // Refresh scrolltrigger to avoid issues with calculations while changing routes.
    setTimeout(function () {
      ScrollTrigger.refresh();
    }, 250);
  },
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
  },
  beforeDestroy() {
    this.tl.kill();
  }
};
</script>