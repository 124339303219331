<template>
  <section ref="testimonials" class="testimonials">

    <div id="video-2" class="card card-video">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe id="player2" class="embed-responsive-item" src="https://www.youtube.com/embed/aWlye_6a5Kw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="content">
        <h3 class="heading text-primary">"You can set it up to be customized however it works best for your situation."</h3>
        <p>2018 Chrysler Pacifica Hybrid owner Dave tells how to schedule charging with a Level 2 charger.</p>
      </div>
    </div>

    <div id="stat-2" class="card card-basic">
      <h3 class="heading text-primary">Like your phone battery – but bigger</h3>
      <span class="subheading">2018 Chrysler Pacifica Hybrid owner Dave tells how to schedule charging with a Level 2 charger.</span>
    </div>

    <div id="stat-3" class="card card-basic">
      <h3 class="heading">Stow-away cord set</h3>
      <span class="subheading">Of course they're standard!</span>
    </div>
    
    <div id="quote-1" class="card card-quote">
      <span class="quote">
        It was a year-and-a-half process of looking at it before I finally went for it because I knew I wanted something with a plug.
      </span>
      <span class="author">Matthew, <br> 2021 Chrysler Pacifica Hybrid Owner</span>
    </div>

    <div id="video-1" class="card card-video">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe id="player1" class="embed-responsive-item" src="https://www.youtube.com/embed/MpgDwDlt7j8?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="content">
        <h3 class="heading text-primary">"If you can plug in your toaster, you can plug in this car."</h3>
        <p>2019 Chrysler Pacifica Hybrid owner Heidi shows how easy it is to plug in the vehicle and monitor its charging progress.</p>
      </div>
    </div>
        
    <div id="img-1" class="card card-img">
      <div class="img-container">
        <img src="../assets/img/charging/testimonial-charging.jpg" alt="2021 Chrysler Pacifica Hybrid - Close-up of charging port.">
      </div>
    </div>

    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 1">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 2">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 3">
  </section>
</template>

<script>
export default {
  name: 'TestimonialsCharging',
  data: () => {
    return {
      loading: true
    }
  },
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  }
}
</script>