<template>
  <div v-if="!loading" id="battery-life" class="bg-light page-wrapper active">

    <div class="hero hero-myth">
      <picture>
        <source media="(max-width: 767px)" :srcset="getHero(content.heroImages[2])">
        <source media="(min-width:768px) and (max-width: 1199px)" :srcset="getHero(content.heroImages[1])">
        <img :src="getHero(content.heroImages[0])" alt="Chrysler Pacifica Hybrid - Charging Facts">
      </picture>
      <div class="title">
        <img :src="getIcon(content.iconLg)" alt="Title Icon">
        <h1 class="myth-title">{{ content.name }}</h1>
      </div>
    </div>

    <section class="intro py-5 mb-lg-5">
      <div class="container-fluid">
        <div class="row">
          <div class="fact col-lg-12 text-center">
            <span class="fact-label">Fact</span>
            <h2 class="fact-heading" v-html="content.pageHeadline">Fact headline</h2>
          </div>
        </div>
      </div>
    </section> 

    <section id="battery-life-animation" ref="animation" class="animation">
      <div class="container-fluid animation-inner" @mouseover="track('Scroll', 'On-site engagement', 'Battery Life - Scroll')">
        <div class="facts-scroller">
          <div class="stats">
            <span class="main-headline d-block">Things the lithium-ion battery <br> may outlive.</span>
            <div class="stat-container">
              <span class="stat stat-1" :style="statStyleObject">Laptops<span id="batterylife-1" class="asterisk d-inline">*</span></span>
              <span class="stat stat-2" :style="statStyleObject">Clothes Dryer<span id="batterylife-2" class="asterisk d-inline">*</span></span>
              <span class="stat stat-3" :style="statStyleObject">Couch<span id="batterylife-3" class="asterisk d-inline">*</span></span>
              <span class="stat stat-4" :style="statStyleObject">Pool Liner<span id="batterylife-4" class="asterisk d-inline">*</span></span>
              <span class="stat stat-5" :style="statStyleObject">Microwave<span id="batterylife-5" class="asterisk d-inline">*</span></span>
            </div>
          </div>
          <div class="graphic">
            <div class="images">
              <img class="gb-img" src="../assets/img/battery-life/graphic-battery.jpg" alt="Battery Image">
              <img src="../assets/img/battery-life/graphic-laptop.jpg" alt="Laptop Image">
              <img src="../assets/img/battery-life/graphic-dryer.jpg" alt="Dryer Image">
              <img src="../assets/img/battery-life/graphic-couch.jpg" alt="Couch Image">
              <img src="../assets/img/battery-life/graphic-pool.jpg" alt="Pool Image">              
              <img src="../assets/img/battery-life/graphic-microwave.jpg" alt="Microwave Image">
              <img src="../assets/img/battery-life/graphic-battery.jpg" alt="Battery Image">
            </div>
          </div>
        </div>
      </div>
    </section>

    
    <PreFooter />
    <TestimonialsBatteryLife />

  </div>
</template>

<script>
import axios from 'axios';
import JQuery from 'jquery';
import PreFooter from '@/components/PreFooter';
import TestimonialsBatteryLife from '@/components/TestimonialsBatteryLife';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

let $ = JQuery;

export default {
  name: 'BatteryLife',
  components: { PreFooter, TestimonialsBatteryLife },
  data: () => {
    return {
      content: null,
      loading: true,
      tl: null,
      statStyleObject: {
        display: 'none'
      }
    }
  },
  metaInfo: {
    title: 'Battery Life | Why Chrysler Pacifica Hybrid',
    meta: [
      { name: 'description', content: 'Chrysler Pacifica Hybrid lithium-ion battery is backed by a 10-year/100,000- or 150,000-mile warranty, depending on state.' },
      { name: 'keywords', content: 'Some, keywords, for, testing' },
      { name: 'og:description', content: 'Chrysler Pacifica Hybrid lithium-ion battery is backed by a 10-year/100,000- or 150,000-mile warranty, depending on state.' },
      { name: 'og:image', content: '/img/hero-lg.5c9c3778.jpg' }
    ]
  },
  mounted() {
    window.addEventListener('resize', this.windowResizeEventHandler, {passive: true});

    $('body').addClass('overflow-y-scroll');
    axios.get(`/data/app.json`).then(response => {
      this.content = response.data[0].facts[3]
      this.loading = false;
      this.$gtag.pageview(this.$route);
      $('body').removeClass('overflow-y-scroll');
    }).then(() => {
      this.$nextTick(function () {

      // Set animation
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.animation,
          pin: true,
          start: "top 0%",
          end: "+=" + (window.innerHeight * 2),
          scrub: 0.60
        }
      });

      this.checkWindowWidth(window.outerWidth);

      // Refresh scrolltrigger to avoid issues with calculations while changing routes.
      setTimeout(function() {
        ScrollTrigger.refresh()
      }, 250)
    });  
    })
  },
  methods: {
    windowResizeEventHandler(event) {
      this.checkWindowWidth(event.target.innerWidth);
    },
    checkWindowWidth(width) {
      // For different screens, different configs for animations are applied
      if (width >= 768) {
        this.statStyleObject = { display: 'none'};
        this.setAnimation(-400, 0);
      } else {
        this.statStyleObject = { display: 'none'};
        this.setAnimation(-250, -20);
      }
    },
    setAnimation(imageYValue, addlImageInc) {
      this.tl
        .clear()
        .to('.facts-scroller', {top: "50%", duration: 5}) // scroll element to center of scene
        
        .to('.stat-1', {y: 0, opacity: 1, display: 'block', duration: 5, delay: 3}, 'img0') // slide in first headline
        .to('.images', {y: imageYValue, duration: 5, delay: 3}, 'img0') // flip graphic to second
        
        .to('.stat-1', {opacity: 0, display: 'none', duration: 0, delay: 3}) // slide out first headline
        .to('.stat-2', {y: 0, opacity: 1, display: 'block', duration: 5}, 'img1') // slide in second headline
        .to('.images', {y: (imageYValue * 2 + addlImageInc), duration: 5}, 'img1') // flip graphic to third
        
        .to('.stat-2', {opacity: 0, display: 'none', duration: 0, delay: 3}) // slide out first headline
        .to('.stat-3', {y: 0, opacity: 1, display: 'block', duration: 5}, 'img2') // slide in second headline
        .to('.images', {y: (imageYValue * 3 + addlImageInc), duration: 5}, 'img2') // flip graphic to third
        
        .to('.stat-3', {opacity: 0, display: 'none', duration: 1, delay: 3}) // slide out first headline
        .to('.stat-4', {y: 0, opacity: 1, display: 'block', duration: 5}, 'img3') // slide in second headline
        .to('.images', {y: (imageYValue * 4 + addlImageInc), duration: 5}, 'img3') // flip graphic to third

        
        .to('.stat-4', {opacity: 0, display: 'none', duration: 1, delay: 3}) // slide out first headline
        .to('.stat-5', {y: 0, opacity: 1, display: 'block', duration: 5}, 'img4') // slide in second headline
        .to('.images', {y: (imageYValue * 5 + addlImageInc), duration: 5}, 'img4')

        /* .to('.stat-1', {y: 0, opacity: 1, display: 'block', duration: 5, delay: 3}, 'img0') // slide in first headline
        .to('.images', {y: -250, duration: 5, delay: 3}, 'img0') // flip graphic to second
        
        .to('.stat-1', {opacity: 0, display: 'none', duration: 0, delay: 3}) // slide out first headline
        .to('.stat-2', {y: 0, opacity: 1, display: 'block', duration: 5}, 'img1') // slide in second headline
        .to('.images', {y: -520, duration: 5}, 'img1') // flip graphic to third
        
        .to('.stat-2', {opacity: 0, display: 'none', duration: 0, delay: 3}) // slide out first headline
        .to('.stat-3', {y: 0, opacity: 1, display: 'block', duration: 5}, 'img2') // slide in second headline
        .to('.images', {y: -770, duration: 5}, 'img2') // flip graphic to third
        
        .to('.stat-3', {opacity: 0, display: 'none', duration: 1, delay: 3}) // slide out first headline
        .to('.stat-4', {y: 0, opacity: 1, display: 'block', duration: 5}, 'img3') // slide in second headline
        .to('.images', {y: -1020, duration: 5}, 'img3') // flip graphic to third */
    },
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
    getIcon(icon) {
      return icon ? require(`../assets/img/icons/${icon}`) : ''
    },
    getHero(image) {
      return image ? require(`../assets/img/${image}`) : ''
    }
  },
  destroyed() {
    this.tl.kill();
    window.removeEventListener('resize', this.windowResizeEventHandler);
  }
}
</script>