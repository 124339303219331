<template>
  <section ref="testimonials" class="testimonials">
      
    <div id="quote-1" class="card card-quote">
      <span class="quote">
        There’s a restaurant we like that is about 25 miles away. While we eat, the van is plugged in getting charged up for free and we make that 50 mile round trip completely electric.
      </span>
      <span class="author">Matthew, <br> 2021 Chrysler Pacifica Hybrid Owner</span>
    </div>

    <div id="img-1" class="card card-img">
      <div class="img-container"> 
        <img src="../assets/img/battery-life/testimonial-battery-life.jpg" alt="2021 Chrysler Pacifica Hybrid - Rear angle connected to charger on garage.">
      </div>
    </div>
    
    <!-- <div id="stat-1" class="card card-basic">
      <span class="heading">Don't worry about the battery!</span>
      <span class="subheading">Simply put, the expected life of the lithium-ion battery is the life of the vehicle.</span>
    </div> -->
    <div id="stat-2" class="card card-basic">
      <span class="subheading mb-2">Lithium-ion battery prices since 2010</span>
      <h3 class="heading">
        Drastically Dropping<br>
        <i class="fa fa-arrow-down text-center ml-auto mr-auto"></i>
      </h3>
      <span class="subheading">
        2010: $1,100 per kilowatt-hour (kWh)<span id="batterylifete-6" class="asterisk">*</span><br>
        2020: $137/kWh (89% drop)<span id="batterylifet-6" class="asterisk">*</span><br>
      </span>
    </div>
    <div id="stat-3" class="card card-basic">
      <h3 class="heading">10 Years</h3>
      <span class="subheading">That's when that battery's first scheduled maintenance happens, <br> at 10 years or 150,000 miles, whichever comes first.</span>
    </div>
    <div id="stat-4" class="card card-basic icon-left">
      <div class="icon">
        <i class="fa fa-recycle text-success"></i>
      </div>
      <div class="content">
        <h3 class="heading mb-2">Lithium-ion batteries can be recycled.</h3>
        <!-- <span class="subheading">They can be recycled.</span> -->
      </div>
    </div>

    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 1">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 2">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 3">

  </section>
</template>

<script>
export default {
  name: 'TestimonialsBatteryLife',
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  }
}
</script>