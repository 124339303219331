<template>
  <div v-if="!loading" id="range" class="bg-light page-wrapper active">
    <div>
      <div class="hero hero-myth">
        <picture>
          <source
            media="(max-width: 767px)"
            :srcset="getHero(content.heroImages[2])"
          />
          <source
            media="(min-width:768px) and (max-width: 1199px)"
            :srcset="getHero(content.heroImages[1])"
          />
          <img
            :src="getHero(content.heroImages[0])"
            alt="Chrysler Pacifica Hybrid - Range Facts"
          />
        </picture>
        <div class="title">
          <img :src="getIcon(content.iconLg)" alt="Title Icon" />
          <h1 class="myth-title">{{ content.name }}</h1>
        </div>
      </div>
    </div>
    <section class="intro py-1 mb-lg-5">
      <div class="container-fluid">
        <div class="row">
          <div class="fact col-lg-12 text-center">
            <span class="fact-label">Fact</span>
            <h2 class="fact-heading" v-html="content.pageHeadline">Fact headline</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="range-animation" class="animation">
      <div class="container-fluid animation-inner">
        <div class="messages">
          <div class="message message-1">
            <h3 class="heading mb-0">32 miles EV range<span id="range-0" class="asterisk">*</span></h3>
            <div class="subheading">
              for the quick trips around town
            </div>
          </div>

          <div class="message message-2">
            <h3 class="heading mb-0">520 miles total driving range<span id="rangea-1" class="asterisk">*</span></h3>
            <div class="subheading">
              for those long family excursions
            </div>
          </div>

          <div class="message message-3">
            <div class="subheading">
              Every errand is run
            </div>
            <h3 class="heading mb-0">
              bringing peace of mind while automatically switching between electric and gas
            </h3>
          </div>

          <div class="message message-4">
            <h3 class="heading mb-0">
              If you are worried about charging
            </h3>
            <div class="subheading">most commutes are within the range<span id="range-2" class="asterisk">*</span></div>
          </div>
          <!-- <div class="message message-6">
            <div class="subheading">This you should know</div>
            <div class="heading">There’s a Fuel And Oil Refresh Mode</div>
          </div> -->
        </div>

        <div class="globe">
          <img
            src="../assets/img/range/hybrid-van-driver.svg"
            alt="Pacifica Hybrid Graphic - Driver's side"
            class="vehicle"
            @mouseover="
              track('Scroll', 'On-site engagement', 'Range - Vechile')
            "
          />
          <img
            ref="globe"
            src="../assets/img/range/range-globe.svg"
            alt="Globe Image"
            class="scene"
          />
        </div>
      </div>
    </section>

    <PreFooter />

    <TestimonialsRange />
  </div>
</template>

<script>
import axios from "axios";
import JQuery from "jquery";
import PreFooter from "@/components/PreFooter";
import TestimonialsRange from "@/components/TestimonialsRange";
//import Scrollbar from 'smooth-scrollbar';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

let $ = JQuery;

export default {
  name: "Range",
  components: { PreFooter, TestimonialsRange },
  data: () => {
    return {
      loading: true,
      content: null,
      tl: null,
    };
  },
  metaInfo: {
    title: "Range | Why Chrysler Pacifica Hybrid",
    meta: [
      {
        name: "description",
        content:
          "Chrysler Pacifica Hybrid (PHEV) myths busted! See how far you can go in the Chrysler Pacifica Hybrid 7-passenger minivan.",
      },
      { name: "keywords", content: "Some, keywords, for, testing" },
      {
        name: "og:description",
        content:
          "Chrysler Pacifica Hybrid (PHEV) myths busted! See how far you can go in the Chrysler Pacifica Hybrid 7-passenger minivan.",
      },
      { name: "og:image", content: "/img/hero-lg.abca891b.jpg" },
    ],
  },
  mounted() {
    $("body").addClass("overflow-y-scroll");
    //$('#app').addClass('range-scroll');
    axios
      .get(`/data/app.json`)
      .then((response) => {
        this.content = response.data[0].facts[0];
        this.loading = false;
        this.$gtag.pageview(this.$route);
        $("body").removeClass("overflow-y-scroll");
      })
      .then(() => {
        setTimeout(() => {

          // Wire up smooth scroll
          // const scroller = document.querySelector('#range');
          // const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document });

          // ScrollTrigger.scrollerProxy('#range', {
          //   scrollTop(value) {
          //     if (arguments.length) {
          //       bodyScrollBar.scrollTop = value;
          //     }
          //     return bodyScrollBar.scrollTop;
          //   }
          // });

          // bodyScrollBar.addListener(ScrollTrigger.update);
          // ScrollTrigger.defaults({ scroller: scroller });


          // Set animation
          this.tl = gsap.timeline({
            scrollTrigger: {
              trigger: ".animation",
              pin: true,
              start: "center center",
              end: () => `+=${window.innerHeight * 3}`,
              scrub: true,
              snap: {
                snapTo: "labelsDirectional", // snap to the closest label in the timeline
                duration: 0.1, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
              }
            },
          });

          this.tl
            .add('message-1')
            .to(".message-1", { display: 'block' })
            .to(".scene", { rotation: -20 })
            
            // Transition of message 1 to 2
            .to(".message-1", { y: 0, display: 'none' })
            .to(".scene", { rotation: 40 })
            .to(".message-2", { y: 0, display: 'block' }, ">")
            .add('message-2')

            // Transition of message 2 to 3
            .to(".message-2", { y: 0, display: 'none' })
            .to(".scene", { rotation: 100 })
            .to(".message-3", { y: 0, display: 'block' }, ">")
            .add('message-3')
            
            // Transition of message 3 to 4
            .to(".message-3", { y: 0, display: 'none' })
            .to(".scene", { rotation: 160 })
            .to(".message-4", { y: 0, display: 'block' }, ">")
            .add('message-4')
            .to({}, {duration: 1});
        }, 1000);
      });
  },
  methods: {
    getIcon(icon) {
      return icon ? require(`../assets/img/icons/${icon}`) : "";
    },
    getHero(image) {
      return image ? require(`../assets/img/${image}`) : "";
    },
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
  },
  destroyed() {
    //$('#app').removeClass('range-scroll');
    //gsap.globalTimeline.clear();
    this.tl.kill();
  },
};
</script>