<template>
  <div class="home">
    <div class="hero hero-home">
      <div class="hero-inner">
        <div class="container-fluid">
          <div class="row align-items-md-center">
            <div class="col-12 col-xl-6 fade-up">
              <h1 class="hero-heading fade-up mb-3">Why Chrysler Pacifica Hybrid?</h1>
              <h2 class="hero-subheading heading-light mb-lg-0">
                Explore the facts and find yourself behind the wheel.
              </h2>
            </div>
            <div class="col-12 col-xl-6">
              <div class="slider-navigation" ref="slideNav">
                <div
                  class="carousel-control-container fade-up"
                  ref="controls"
                  :class="{ fixed: controlsFixed }"
                >
                  <div class="carousel-controls">
                    <button
                      class="btn btn-icon indicator trackable"
                      v-for="(fact, index) in facts"
                      :key="index"
                      :id="`slider-control-${index}`"
                      :class="indicatorActive === index ? 'active' : null"
                      :data-index="index"
                      :title="fact.name"
                      @click="
                        indicatorActive = index;
                        goToSlide(index);
                        track('Click', 'Navigation Header', fact.eventLabel);
                      "
                    >
                      <img
                        class="fact-icon"
                        :src="getIcon(fact.iconSm)"
                        alt="Fact Icon"
                      />
                      <span class="title ml-auto mr-auto">{{ fact.name }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-accent">
        <img
          class="w-100"
          src="../assets/img/bg-repeat.png"
          alt="background accent image"
        />
      </div>
    </div>
    <div class="feature-container">
      <div class="slider-container">
        <div class="row">
          <slick
            v-if="!loading"
            class="col-12 slider transition"
            ref="slick"
            :options="slickOptions"
            @init="init"
            @beforeChange="handleBeforeChange"
            @afterChange="handleAfterChange"
          >
            <div
              v-for="(item, index) in facts"
              :key="`item-${item.component}-${index}`"
              class="slide-item"
              :data-myth="item.component.toLowerCase()"
            >
              <div class="inner">
                <div class="myth">
                  <div class="heading-container container-myth">
                    <h3 class="myth-heading">
                      <span class="title">Myth: </span>
                      <span class="desc" v-html="item.mythHeadline">Myth Headline</span>
                    </h3>
                  </div>
                  <div class="heading-container container-facts">
                    <h3 class="myth-heading">
                      <span class="title">Fact: </span>
                      <span class="desc" v-html="item.factHeadline">Fact Headline</span>
                    </h3>
                  </div>
                  <button
                    v-for="(hotspot, index) in item.hotspots"
                    :key="`range-hotspot-${index}`"
                    :id="hotspot.id"
                    class="hotspot trackable"
                    data-toggle="popover"
                    data-html="true"
                    :title="hotspot.title"
                    :data-content="hotspot.desc"
                    :style="`animation-delay: ${(index + 1) * 0.25}s`"
                    @mouseover="track('Hover', 'On-site Event', hotspot.eventLabel)"
                  >
                    <img src="../assets/img/slider/icon-hotspot.png" alt="Hotspot Icon" />
                  </button>
                </div>

                <div class="fact">
                  <!-- <div class="heading-container">
                    <span class="title text-secondary">Fact</span>
                    <h3 class="myth-heading" v-html="item.factHeadline">Fact Headline</h3>
                  </div> -->
                  <div class="video-container" aria-hidden="true">
                    <video loop muted playsinline aria-hidden="true">
                      <source :src="getVideo(item.factAnimation)" type="video/mp4" />
                      <p>Your browser doesn't support HTML5 video.</p>
                    </video>
                  </div>

                  <div class="cta-buttons">
                    <router-link
                      :to="item.href"
                      class="btn btn-primary ml-0 mr-0"
                      @click.native="
                        track('Click', 'On-site Event', 'Explore ' + item.name)
                      "
                      >Explore {{ item.name }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </slick>
        </div>
        <button
          id="slider-prev"
          class="btn step"
          @click="track('Click', 'Left Right Nav', 'Left scroll')"
        >
          <i class="fa fa-arrow-left"></i><span class="sr-only">Navigate Back</span>
        </button>
        <button
          id="slider-next"
          class="btn step"
          @click="
            next($event);
            track('Click', 'Left Right Nav', 'Right scroll');
          "
        >
          <i class="fa fa-arrow-right"></i><span class="sr-only">Navigate Forward</span>
        </button>
      </div>
    </div>

    <TestimonialsHome />
  </div>
</template>

<script>
import axios from "axios";
import Slick from "vue-slick";
import JQuery from "jquery";
import TestimonialsHome from '@/components/TestimonialsHome'
let $ = JQuery;

export default {
  name: "Home",
  components: { Slick, TestimonialsHome },
  data: () => {
    return {
      indicatorActive: 0,
      controlsFixed: false,
      controlsPosition: 0,
      windowWidth: 0,
      facts: null,
      mythExpanded: false,
      slickOptions: {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 30000,
        prevArrow: "#slider-prev",
        nextArrow: "#slider-next",
        dots: false,
        fade: true,
        pauseOnHover: true,
      },
      loading: true,
    };
  },
  metaInfo: {
    title: "Why Chrysler Pacifica Hybrid",
    meta: [
      {
        name: "description",
        content:
          "Separate the myths from the facts and learn why Chrysler Pacifica Hybrid is among the best hybrids money can buy.",
      },
      {
        name: "og:description",
        content:
          "Separate the myths from the facts and learn why Chrysler Pacifica Hybrid is among the best hybrids money can buy.",
      },
    ],
  },
  mounted() {
    // Set up functionality for scrolling carousel navigation. This is removed upon destroying the view.
    window.addEventListener("resize", this.onResize, { passive: true });
    this.windowWidth = window.innerWidth;

    let mq = window.matchMedia("(max-width: 991px)");
    if (mq.matches) {
      window.dispatchEvent(new Event("resize"));
    }

    window.addEventListener("scroll", this.onScroll, { passive: true });

    axios
      .get(`/data/app.json`)
      .then((response) => {
        this.facts = response.data[0].facts;
        this.loading = false;
        this.$gtag.pageview(this.$route);
      })
      .then(() => {
        // Activate popovers
        $('[data-toggle="popover"]').popover({ html: true });

        $(".slick-slide.slick-active video").addClass("active");
        $(".slick-slide.slick-active video")[0].currentTime = 0;
        $(".slick-slide.slick-active video")[0].play();

        // Popover functionality
        $('.slick-slide [data-toggle="popover"]').removeClass("scale-in-out");
        $('.slick-slide.slick-active [data-toggle="popover"]').addClass("scale-in-out");

        setTimeout(() => {
          $('.slick-slide.slick-active [data-toggle="popover"]:first').popover("show");
        }, 2000);
      });

    // Highlight myth when returned from child page by click Go Back button
    if (localStorage.getItem("childPageIndex")) {
      this.goToSlide(parseInt(localStorage.getItem("childPageIndex")));
      localStorage.removeItem("childPageIndex");
    }
  },
  methods: {
    indicatorStep(direction) {
      let max = this.facts.length - 1;
      if (direction == "forwards" && this.indicatorActive < max) {
        this.indicatorActive++;
      }
      if (direction == "backwards" && this.indicatorActive > 0) {
        this.indicatorActive--;
      }
    },

    // Slider boilerplate methods and event hooks
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    init() {
      setTimeout(() => {
        $(".slider").find(".slick-current").addClass("active-slide");
      }, 1000);
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    goToSlide(index) {
      this.$refs.slick.goTo(index);
    },

    // Slider Events listeners
    handleBeforeChange() {
      $(".slick-slide").removeClass("active-slide");

      // Hide all popovers from previous slide
      $('[data-toggle="popover"]').popover("hide");
    },
    handleAfterChange(event, slick, currentSlide) {
      $(".slick-slide.slick-active").addClass("active-slide");

      this.indicatorActive = currentSlide;

      setTimeout(() => {
        // Update indicators and toggle video display
        $(".slick-slide video").removeClass("active");

        // reset and play video when slide is active
        $(".slick-slide.active-slide video").addClass("active");
        $(".slick-slide.active-slide video")[0].currentTime = 0;
        $(".slick-slide.active-slide video")[0].play();

        $('.slick-slide [data-toggle="popover"]').removeClass("scale-in-out");
        $('.slick-slide.active-slide [data-toggle="popover"]').addClass("scale-in-out");
        setTimeout(() => {
          $('.slick-slide.active-slide [data-toggle="popover"]:first').popover("show");
        }, 1500);
      }, 750);
    },
    toggleCollapseMobile() {
      this.mythExpanded = !this.mythExpanded;

      if (!this.mythExpanded) {
        $('[data-toggle="popover"]').popover("hide");
      }
    },
    getIcon(icon) {
      return icon ? require(`../assets/img/icons/${icon}`) : "";
    },
    getVideo(src) {
      return src ? require(`../assets/video/${src}`) : "";
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.controlsPosition = this.$refs.slideNav.getBoundingClientRect().top;
      //this.controlsPosition = this.$refs.controls.getBoundingClientRect().top
    },

    onScroll() {
      this.windowWidth < 992 && window.scrollY > this.controlsPosition
        ? (this.controlsFixed = true)
        : (this.controlsFixed = false);

      // Added to fix sticking when scrolling up too fast
      if (this.windowWidth < 992 && window.scrollY < 80) {
        this.controlsFixed = false;
      }
    },

    emitDisclaimer(disclaimer) {
      this.$emit("disclaimerTrigger", disclaimer);
    },
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
  },
  beforeDestroy() {
    // Hide all popovers from previous slide
    $(".popover").remove();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
