import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta';
import VueYoutube from 'vue-youtube';

import axios from 'axios'
import 'bootstrap'
import 'popper.js/dist/popper.min.js'
import './global.js'

import "core-js/stable";
import "regenerator-runtime/runtime";


Vue.prototype.$http = axios;
Vue.config.productionTip = false;




// Directives
Vue.directive('click-outside', {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {  //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) { // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
        // binding.value(); run the arg
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent)
  },
  unbind: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent)
  }
});

Vue.use(VueYoutube);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_TRACKING }
});

Vue.use(VueMeta, {refreshOnceOnNavigation: true});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
