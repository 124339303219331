<template>
  <div id="app">
    
    <Header id="nav" />
    <router-view />
    <Footer />
    
    <ChatModal />
    <DisclaimerModal :currentRoute="$route.name" />
    <TrademarksModal />
  </div>
</template>

<style lang="scss">
@import "assets/scss/main.scss";
</style>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ChatModal from "@/components/ChatModal.vue";
import DisclaimerModal from "@/components/DisclaimerModal.vue";
import TrademarksModal from "@/components/TrademarksModal.vue";

export default {
  name: "App",
  components: { Header, Footer, ChatModal, DisclaimerModal,TrademarksModal },
  mounted() {
    
    // Holidays for Chat Modal
    axios.get(`/data/holidays.json`)
      .then( (response) => this.holidays = response.data )
      .then( () => this.businessHours() )

    // window.$zoho.salesiq.ready=function()
    const that = this;
    if (window.$zoho.salesiq.chatbutton) {
      window.$zoho.salesiq.chatbutton.click(function () {
        that.track("Click", "Fact Finder page", "Contact an expert");
      });
    }

    //this.$nextTick(() => this.businessHours())
  },
  methods: {
    track(action, category, label) {
        this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
    businessHours() {
      let today = moment().tz('America/New_York');
      let chats = document.querySelectorAll('.zsiq_floatmain');
      let headingOnline = document.querySelector('#chat-modal-heading-online');
      let headingOffline = document.querySelector('#chat-modal-heading-offline');
      let messageOnline = document.querySelector('#message-online');
      let messageOffline = document.querySelector('#message-offline');
      let chatBtn = document.querySelector('#chat-tab');
      let headingHoliday = document.querySelector('#chat-modal-heading-holiday');
      let messageHoliday = document.querySelector('#message-holiday');
      let subHeadingHoliday = document.querySelector('#chat-sub-hd-holiday');
      let subHeadingOpen = document.querySelector('#chat-sub-hd-open');
      let isTodayHoliday = false;
      let workingDateAfterHoliday = '';

      let todayInMMDDYYYYFormat = today.format('MM-DD-YYYY');

      // Array of holidays with next working day info, day is in in MM-DD-YYYY format
      // let holidaysList = [
      //   {day: '07-05-2021', workingDateAfterHoliday: 'July 6, 2021'}
      // ];

      this.holidays.forEach((holiday) => {
        if (todayInMMDDYYYYFormat === holiday.day) {
          isTodayHoliday = true;
          workingDateAfterHoliday = holiday.workingDateAfterHoliday;
        }
      });

      // Check if today is a holiday or not
      if (isTodayHoliday) {
        // Activate holiday messaging
        headingOffline.style.display = 'none';
        headingOnline.style.display = 'none';
        headingHoliday.style.display = 'block';

        subHeadingOpen.style.display = 'none';
        subHeadingHoliday.style.display = 'block';
        document.querySelector('#working-date-after-holiday').innerHTML = workingDateAfterHoliday;

        messageOffline.style.display = 'none';
        messageOnline.style.display = 'none';
        messageHoliday.style.display = 'block';

        this.disableAndHideZohoChat(chatBtn, chats);

      } else {
        // Today is not holiday
        headingHoliday.style.display = 'none';
        subHeadingOpen.style.display = 'block';
        subHeadingHoliday.style.display = 'none';
        messageHoliday.style.display = 'none';

        // check if we're within business hours
        if ((today.day() == 0 || today.day() == 6) || (today.hours() < 11 || today.hours() >= 20)) {
          // Activate offline messaging
          headingOnline.style.display = 'none';
          messageOnline.style.display = 'none';
          
          headingOffline.style.display = 'block';
          messageOffline.style.display = 'block';

          this.disableAndHideZohoChat(chatBtn, chats);

        } else {
          // Activate online messaging
          headingOnline.style.display = 'block';
          messageOnline.style.display = 'block';
          
          headingOffline.style.display = 'none';
          messageOffline.style.display = 'none';
          chatBtn.classList.remove('disabled');
          chatBtn.disabled = false;
        }
      }
    },
    disableAndHideZohoChat(chatBtn, chats) {
      chatBtn.classList.add('disabled');
      chatBtn.disabled = true;
      
      // Hide Zoho chats
      chats.forEach(chat => {
        chat.style.visibility = 'hidden !important';
        chat.style.color = 'red';
        chat.style.display = 'none !important';
      })
    }
  },
  watch: {
    '$route': function() { this.businessHours(); }
  }
};
</script>


