<template>
  <div>
    
    <header id="site-header" :class="$route.name != 'Home' ? 'fixed-nav' : null" v-if="!loading">
      <div class="container-fluid">
        <div class="logo-container">
          <router-link to="/" id="site-logo" @click="track('Click', 'Header', 'Site Logo'); backToHome()" class="d-block" title="Home" aria-label="Home">
            <img src="../assets/img/site-logo.png" alt="Chrysler Pacifica Hybrid - Logo" />
          </router-link>
        </div>
        <nav class="primary-nav d-none d-lg-block ml-auto mr-xl-5">
          <a v-for="item in items" :key="item.id" :id="`nav-item-${item.id}`" class="nav-item" :href="item.href" :target="item.external ? '_blank' : null" 
            :data-toggle="item.modal ? 'modal' : null" 
            :data-target="item.modal ? item.href : null" 
            @click="closeFactsNav(); track('Click', item.eventCategoryHeader, item.eventLabel)"
            onclick="javascript:$zoho.salesiq.floatwindow.visible('hide');">
            <i class="fa" :class="`fa-${item.icon}`"></i> {{ item.name }}
          </a>
        </nav>
        <div class="nav-controls ml-auto ml-lg-0">
          <button 
            id="overlay-toggle-mobile" 
            class="btn mobile-toggle mr-4 d-lg-none"
            @click="toggleMobileNav(); track('Click', 'Header', 'Fact Finder')"
          >
            <i :class="`fa fa-${mobileNavActive ? 'times' : 'bars'}`"></i>
            <span class="sr-only">Navigation Toggle</span>
          </button>
          <button
            id="overlay-toggle"
            class="btn mobile-toggle btn-gradient-success fact-finder-cta"
            @click="toggleFactsNav(); track('Click', 'Header', 'Fact Finder')"
          >
            <i :class="`fa fa-${factsNavActive ? 'times' : 'th'}`"></i>
            <span class="fact-finder-cta-text">Fact Finder</span>
            <span class="sr-only">Facts Navigation</span>
          </button>
        </div>
      </div>
    </header>

    <transition name="fadeIn">
      <OverlayMobile
        v-if="mobileNavActive"
        :items="items"
        :isActive="mobileNavActive"
        @active="toggleMobileNav"
      />
      <OverlayFacts
        v-if="factsNavActive"
        :items="facts"
        :isActive="factsNavActive"
        @active="toggleFactsNav"
      />
    </transition>
  </div>
</template>

<script>
import JQuery from 'jquery'
import axios from 'axios'
import OverlayMobile from '@/components/OverlayMobile'
import OverlayFacts from '@/components/OverlayFacts'
let $ = JQuery

export default {
  name: "Header",
  components: { OverlayMobile, OverlayFacts },
  data: () => {
    return {
      loading: true,
      items: null,
      facts: null,
      mobileNavActive: false,
      factsNavActive: false
    };
  },
  mounted() {
    axios.get(`/data/app.json`).then(response => {
      this.items = response.data[0].navItems
      this.facts = response.data[0].facts
      this.loading = false
    })
  },
  methods: {
    toggleMobileNav() { 
      this.mobileNavActive = !this.mobileNavActive 
      this.factsNavActive = false
      $('body').toggleClass('modal-open');
    },
    toggleFactsNav() { 
      this.factsNavActive = !this.factsNavActive 
      this.mobileNavActive = false
      $('body').toggleClass('modal-open');
    },
    closeFactsNav() {
      this.factsNavActive = false;
      $('body').removeClass('modal-open');
    },
    backToHome() {
      this.closeFactsNav();
    },
    track(action, category, label) {
      this.$gtag.event(action, {
        'event_category': category,
        'event_label': label,
      });
    }
  }
}
</script>