<template>
  <div ref="calcContainer" class="form-container bg-light" :class="showResults ? 'results' : null">
    <div class="container-fluid">
      <div class="row">
        <div class="img-container col-lg-6" :class="showResults ? 'd-lg-none' : null">
          <img src="../assets/img/affordability/front-3-4.png" alt="Chrysler Pacifica Hybrid - Front 3/4">
        </div>
        <div id="calculator-form" class="view col-lg-6" v-if="!showResults">
          <div class="heading-container mb-4">
            <h3 class="heading">See how much you'll save</h3>
            <p>
              Use the calculator to estimate your yearly fuel costs and the number of trips you'll make to the pump, and compare Pacifica Hybrid savings with other vehicles<span id="affordability-5" class="asterisk">*</span>.
            </p>
          </div>
          <form id="calculator-affordability" @submit.prevent="submitForm()">
            <div class="form-group">
              <label for="state-select">Select your state:</label>
              <select id="state-select" class="custom-select form-control custom-focus-b" v-model="stateSelection" @change="updateStateName($event)">
                <option v-for="(state, index) in states" :key="`state-${state.value}-${index}`" :value="state.price">{{ state.label }}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="miles-input">Enter your driving distance (miles):</label>
              <input type="text" class="form-control custom-focus-b" :class="formHasError ? 'has-error' : null" id="miles-input" placeholder="Miles" maxlength="9" v-model="miles" @keydown="isNumber($event)">
              <small v-if="formHasError" class="text-danger error-msg">This field is required.</small>
            </div>
            <div id="radios" class="form-group">
              <label for="miles-input">Select your distance:</label>
              <div class="custom-control custom-radio">
                <input type="radio" id="distance-day" class="custom-control-input" value="day" v-model="distance">
                <label class="custom-control-label" for="distance-day">Per Day</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" id="distance-year" class="custom-control-input" value="year" v-model="distance">
                <label class="custom-control-label" for="distance-year">Per Year</label>
              </div>
            </div>
            
            <button type="submit" class="btn btn-primary">Start Calculating</button>
          </form>
        </div>
        <div id="calculator-results" class="view bg-light" v-if="showResults">
          <div class="img-container d-none d-lg-block">
            <img src="../assets/img/affordability/front-3-4.png" alt="Chrysler Pacifica Hybrid - Front 3/4">
          </div>
          <div class="heading-container mb-4">
            <h3 class="heading">Check out those savings</h3>
            <div class="controls">
              <button class="btn btn-secondary" @click="resetForm()">Edit</button>
              <div class="meta">
                <p>State: <span class="text-uppercase">{{ stateName }}</span></p>
                <p>Miles Driven Per Year: <span class="text-uppercase">{{ formatCommas(totalMiles) }}</span></p>
              </div>              
            </div>
          </div>
          <div class="results-container">
            <div class="results d-lg-none" id="visits">
              <h4 class="results-heading">Annual Visits to Pump</h4>
              <div class="results-table">
                <div class="item">
                  <span class="title">2021 <br class="d-none d-md-block"> Pacifica Hybrid</span>
                  <span class="output">{{ formatCommas( Math.ceil( ((this.totalMiles * 0.45) / this.fuelRange) )) }}</span>
                </div>
                <div class="item">
                  <span class="title">2021 Honda Odyssey</span>
                  <span class="output">{{ formatCommas( Math.ceil( (this.totalMiles / this.comparisonVehicles[0].mpg) / this.comparisonVehicles[0].tankSize) ) }}</span>
                </div>
                <div class="item">
                  <span class="title">2021 <br class="d-none d-md-block"> Toyota Sienna</span>
                  <span class="output">{{ formatCommas( Math.ceil( (this.totalMiles / this.comparisonVehicles[1].mpg) / this.comparisonVehicles[1].tankSize) ) }}</span>
                </div>
              </div>
            </div>
            <div class="results d-lg-none" id="cost">
              <h4 class="results-heading">Estimated Annual Fuel Cost</h4>
              <div class="results-table">
                <div class="item">
                  <span class="title">2021 <br class="d-none d-md-block"> Pacifica Hybrid</span>
                  <span class="output">{{ formatPrice( Math.round(electricCost + fuelCost), 0 ) }}</span>
                </div>
                <div class="item">
                  <span class="title">2021 Honda Odyssey</span>
                  <span class="output">{{ formatPrice( Math.round( (this.totalMiles / this.comparisonVehicles[0].mpg) * this.avgFuelCost ), 0 ) }}</span>
                </div>
                <div class="item">
                  <span class="title">2021 <br class="d-none d-md-block"> Toyota Sienna</span>
                  <span class="output">{{ formatPrice( Math.round( (this.totalMiles / this.comparisonVehicles[1].mpg) * this.avgFuelCost ), 0 ) }}</span>
                </div>
              </div>
            </div>
            <div class="results-desktop d-none d-lg-flex">
              <div class="results-table-headings">
                <span></span>
                <span>2021 Pacifica Hybrid</span>
                <span>2021 Honda Odyssey</span>
                <span>2020 Toyota Sienna</span>
              </div>
              <!-- (miles / mpg) /  tank size = visits -->
              <div class="results-table-row">
                <div><span>Annual Visits to Pump</span></div>
                <div>{{ formatCommas( Math.ceil( ((this.totalMiles * 0.45) / this.fuelRange) )) }}</div>
                <div>{{ formatCommas( Math.ceil( (this.totalMiles / this.comparisonVehicles[0].mpg) / this.comparisonVehicles[0].tankSize) ) }}</div>
                <div>{{ formatCommas( Math.ceil( (this.totalMiles / this.comparisonVehicles[1].mpg) / this.comparisonVehicles[1].tankSize) ) }}</div>
              </div>
              <!-- Total miles / gal * fuel cost -->
              <div class="results-table-row">
                <div><span>Estimated Annual Fuel Cost</span></div>
                <div>{{ formatPrice( Math.round(electricCost + fuelCost), 0 ) }}</div>
                <div>{{ formatPrice( Math.round( (this.totalMiles / this.comparisonVehicles[0].mpg) * this.avgFuelCost ), 0 ) }}</div>
                <div>{{ formatPrice( Math.round( (this.totalMiles / this.comparisonVehicles[1].mpg) * this.avgFuelCost ), 0 ) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'CalculatorAffordability',
  data: () => {
    return {
      loading: true,
      states: null,
      showResults: false,
      formHasError: false,

      // Models
      stateSelection: '',
      stateName: '',
      distance: '',
      miles: '',
      totalMiles: '',
      electricCost: '',
      fuelCost: '',
      avgFuelCost: 0,
      tankSize: 16.5,
      mpg: 32,
      fuelRange: 488,

      // Compare Vehicles
      comparisonVehicles: [
        {
          name: "2021 Honda Odyssey",
          mpg: 28,
          tankSize: 19.5
        },
        {
          name: "2020 Toyota Sienna",
          mpg: 26,
          tankSize: 20
        }
      ]
    }
  },
  mounted() {
    
    // Get avg fuel cost price file
    axios
      .get(process.env.VUE_APP_AFFORDABILITY_AVG_FUEL_COST)
      .then(response => this.avgFuelCost = Number(response.data.regular))
      .finally(() => {

        // Get state-specific electric costs
        axios.get(`/data/stateElectricCosts.json`).then(response => {
          this.states = response.data[0].states;
          this.stateSelection = this.states[0].price;
          this.stateName = this.states[0].label;
          this.distance = 'day';
          this.loading = false;
        });

      });

    // Disclaimer that will need to be added back to site when we enable calculator again
    // {
    //     "ref": "Compare Pacifica Hybrid savings with other vehicles",
    //     "desc": "Comparison information based on 2021 Chrysler Pacifica models against most up-to-date publicly available competitor estimates from the EPA website. Actual mileage may vary. Estimated savings based on national average gas price according to the U.S. Department of Energy, and on state average price of electricity according to the National Renewable Energy Laboratory. Estimated savings based on 55% of annual miles driven on electricity. See <a href=\"https://afdc.energy.gov/vehicles/electric_emissions_sources.html\" target=\"_blank\" rel=\"noopener\">www.afdc.energy.gov/vehicles/electric_emissions_sources.html</a> for details. Your actual gas savings will vary due to a number of factors including driving conditions, vehicle maintenance and changes in energy costs."
    //   }
  },
  methods: {
    
    // Used for output on results.
    updateStateName(event) {
      this.stateName = event.target.options[event.target.selectedIndex].text
    },

    // Form Submission
    submitForm() {
      if (this.miles == '') {
        this.formHasError = true
      }else {

        // Grab form data
        let formData = {
          'state': this.stateSelection,
          'distance': this.distance,
          'miles': this.miles
        }

        // Set values
        let energyCost = Number(formData['state'])
        let miles = Number(formData['miles'])
        let distance = formData['distance']
        this.totalMiles = distance == 'day' ? miles * 365 : miles

        //let pacificaTotalBatterySize = 16
        //let pacificaTotalBatteryMiles = 32
        //let pacificaTotalMPG = 32
        let combE = 41

        
        //1. Get the number of charges need to go 100 miles (industry standard speak)
        //let numChargesPer100Miles = 100 / pacificaTotalBatteryMiles;

        //2. Calculate the kWh used per 100 miles of driving. No clue why we divide by 100 at the end.
        let kwhUsedPer100Miles = combE / 100;
        //let kwhUsedPer100Miles = (numChargesPer100Miles * pacificaTotalBatterySize) / 100;

        //3. Calculate the total electric cost (.55 is 55% electricty use)
        this.electricCost = energyCost * kwhUsedPer100Miles * ( this.totalMiles * 0.55 );

        //4. Calculate the total fuel cost (.45 is 45% fuel use)
        this.fuelCost = this.avgFuelCost * this.tankSize * ( ( this.totalMiles * 0.45 ) / this.fuelRange );

        this.showResults = true
        this.$refs.calcContainer.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      }

    },

    resetForm() {
      this.showResults = false;
      this.formHasError = false;
      this.$refs.calcContainer.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    },

    // Check on miles input to force numbers
    isNumber(event) {
      if (
        (event.keyCode >= 48 && event.keyCode <= 57) || // numbers on row
        (event.keyCode >= 96 && event.keyCode <= 105) || // numbers on numpad (win)
        event.keyCode == 8 || // backspace
        event.keyCode == 46 // delete
      ) {
        this.formHasError = false
        return
      } else {
        event.preventDefault();
      }
    },

    // Format output of dollar values
    formatPrice(value, spaces = 2) {
      let val = (value/1).toFixed(spaces)
      return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    
    // Format output of dollar values
    formatCommas(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>