
<template>
  <footer id="global-footer" v-if="!loading">
    <div id="footer-links" class="py-lg-5">
      <div class="container-fluid">
        <div class="row">
          <div
            class="panel-group accordion col-12"
          id="footer-accordion" 
          >
            <div class="row">
              <div class="col-lg-5 col-xl-7 footer-column feature-links">
                <div class="column-heading" id="links-heading">
                  <button 
                    id="links-heading-btn"
                    class="btn btn-link btn-block font-secondary-light"
                    type="button"
                    data-toggle="collapse"
                    data-target="#links-collapse"
                    aria-expanded="false"
                    aria-controls="links-collapse"
                    @click="track('Click', 'Footer', 'Links of Interest')"
                  >
                    Links of Interest
                  </button>
                </div>
                <div
                  id="links-collapse"
                  class="panel-collapse collapse"
                  aria-labelledby="links-heading-btn"
                >
                  <div class="panel-body">
                    <ul class="mb-xl-0">
                      <li v-for="item in featuredItems" :key="item.id">
                        <a
                          class="nav-item trackable" 
                          id="footer-contact-modal"
                          :href="item.href"
                          :target="item.external ? '_blank' : null"
                          :data-toggle="item.modal ? 'modal' : null"
                          :data-target="item.modal ? item.href : null"
                          @click="track('Click', item.eventCategoryFooter, item.name)" 
                          onclick="javascript:$zoho.salesiq.floatwindow.visible('hide');"
                        >
                          <i
                            class="fa"
                            :class="
                              !item.icon
                                ? 'invisible fa-envelope'
                                : `fa-${item.icon}`
                            "
                          ></i>
                          {{ item.name }}
                        </a>
                      </li>
                      <li>
                        <a
                          class="nav-item trackable"
                          href="#"
                          data-toggle="modal"
                          data-target="#disclaimer-modal" 
                          id="disclaimer-toggle-modal" 
                          @click="track('Click', 'Footer', 'Disclaimers')"
                          ><i class="fa fa-asterisk"></i> Disclaimers</a
                        >
                      </li>
                      <li>
                        <router-link to="/faq" id="faq-link"  class="nav-item trackable" @click.native="track('Click', 'Footer', 'FAQ')"
                          ><i class="fa fa-question-circle"></i>
                          FAQ</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-7 col-xl-5 footer-column"
                v-for="item in items"
                :key="item.slug"
              >
                <div
                  class="column-heading"
                  :id="`${item.slug}-heading`"
                >
                  <button
                    :id="`${item.slug}-btn`"
                    class="btn btn-link btn-block font-secondary-light trackable"
                    type="button"
                    data-toggle="collapse"
                    :data-target="`#${item.slug}-collapse`"
                    aria-expanded="false"
                    :aria-controls="`${item.slug}-collapse`"
                    @click="track('Click', 'Footer', item.groupName)"
                  >
                    {{ item.groupName }}
                  </button>
                </div>
                <div
                  :id="`${item.slug}-collapse`"
                  class="panel-collapse collapse"
                  :aria-labelledby="`${item.slug}-btn`"
                >
                  <div class="panel-body">
                    <div id="column-chrysler" class="column-inner">
                      <p class="column-title"><a href="https://www.chrysler.com/" target="_blank" rel="noopener" @click="track('Click', 'Sent To Chrysler', link.title+' + footer')">Chrysler.com</a></p>
                      <ul>
                        <li v-for="(link, index) in item.links" :key="index" ><a :href="link.href" target="_blank" rel="noopener" @click="track(link.action, link.category, link.label)">{{ link.title }}</a></li>
                      </ul>
                    </div>
                    <div id="column-social" class="column-inner">
                      <p class="column-title">Follow Us</p>
                      <ul>
                        <li>
                          <a
                            href="https://www.instagram.com/chrysler/"
                            target="_blank" rel="noopener"
                            title="Instagram"
                            @click="track('Click', 'Follow us', 'Instagram')" 
                            class="trackable" 
                            ><i class="fa fa-instagram"></i
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/Chrysler"
                            target="_blank" rel="noopener"
                            title="Twitter"
                            @click="track('Click', 'Follow us', 'Twitter')" 
                            class="trackable"
                            ><i class="fa fa-twitter-square"></i
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/chrysler"
                            target="_blank" rel="noopener"
                            title="Facebook"
                            @click="track('Click', 'Follow us', 'Facebook')"
                            class="trackable"
                            ><i class="fa fa-facebook-square"></i
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/user/chrysler"
                            target="_blank"  rel="noopener"
                            title="YouTube"
                            @click="track('Click', 'Follow us', 'YouTube')" 
                            class="trackable"
                            ><i class="fa fa-youtube-square"></i
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://pinterest.com/chryslerautos"
                            target="_blank" rel="noopener"
                            title="Pinterest"
                            @click="track('Click', 'Follow us', 'Pinterest')" 
                            class="trackable"
                            ><i class="fa fa-pinterest-square"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footer-copyright" class="bg-black">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 d-md-flex align-items-md-center">
            <a href="https://www.chrysler.com/" target="_blank" rel="noopener" id="footer-logo" class="trackable"
              ><img
                src="https://www.chrysler.com/content/dam/fca-brands/na/chrysler/en_us/global/header/header_logo/chrysler-logo.png"
                alt="Chrysler Logo"
            /></a>
            <p class="mb-md-0 ml-md-5 text-lg-left">
              &copy;2021 FCA US LLC. All Rights Reserved. Chrysler is a registered trademark of FCA US LLC. <br> 
              <a href="https://www.chrysler.com/crossbrand_us/privacy" target="_blank" rel="noopener" class="trackable" id="privacy-footer">Privacy Policy</a> | 
              <a href="https://www.chrysler.com/crossbrand_us/terms-of-use" target="_blank" rel="noopener" class="trackable" id="terms-footer">Terms of Use</a> |
              <a href="https://www.chrysler.com/universal/Copyright.html" target="_blank" rel="noopener" class="trackable" id="copyright-footer">Copyright</a> | 
              <a href="#" data-toggle="modal" data-target="#trademarks-modal" id="legal-modal-toggle" class="trackable">Legal, Safety, and Trademark Information</a> | 
              <a href="https://privacyportal-cdn.onetrust.com/dsarwebform/abdee64f-f547-46bd-97a7-f56d58479fce/1c3a169b-1230-4a2e-b664-8785e3763e2c.html" target="_blank" rel="noopener" class="trackable" id="info-footer">Do Not Sell My Personal Information</a> | 
              <a href="https://fcacommunity.force.com/Chrysler/s/article/What-steps-do-you-take-to-ensure-website-accessibility" target="_blank" rel="noopener" class="trackable" id="accessibility-footer">Accessibility</a>
              <br>
              <router-link to="/site-map/" id="sitemap-link"  @click.native="track('Click', 'Footer', 'Sitemap')">Sitemap</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  data: () => {
    return {
      loading: true,
      items: null,
      featuredItems: null,
    };
  },
  mounted() {
    axios.get(`/data/app.json`).then((response) => {
      this.featuredItems = response.data[0].navItems;
      this.items = response.data[0].footerLinks;
      this.loading = false;
    });
  },
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  },
};
</script>