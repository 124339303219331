<template>
  <div id="not-found" class="gradient-light">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h1>404</h1>
          <h2 class="mb-5">The page you requested does not exist.</h2>
          <p><router-link to="/" class="btn btn-secondary">Take me back home</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  metaInfo: {
    title: '404 Page Not Found | Why Chrysler Pacifica Hybrid'
  }
}
</script>