<template>
  <section ref="testimonials" class="testimonials">
      <div id="video-1" class="card card-video">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe id="player1" class="embed-responsive-item" src="https://www.youtube.com/embed/lGXhc9ntRJY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="content">
          <h3 class="heading text-primary">"It makes her feel safe, it makes me feel fun."</h3>
          <p>2018 Chrysler Pacifica Hybrid owners Dave and Angela share how the vehicle is the perfect fit for their busy family.</p>
        </div>
      </div>

      <div id="quote-1" class="card card-quote">
        <span class="quote">
          I had another minivan for a decade and thought I wanted the new model, but we test drove the Chrysler Pacifica Hybrid and we couldn’t believe how much we loved it!
        </span>
        <span class="author">Heidi, <br> 2019 Chrysler Pacifica Hybrid Owner</span>
      </div>
      
      <div id="quote-2" class="card card-quote">
        <span class="quote"> 
          Driving to and from work is a gasless experience for me.
        </span>
        <span class="author">Matthew, <br> 2021 Chrysler Pacifica Hybrid Owner</span>
      </div>

      <div id="quote-3" class="card card-img">
        <div class="img-container">
          <img src="../assets/img/range/steve-testimonial.jpg" alt="Quote Placeholder Image">
        </div>
        <div class="content">
          <span class="description">
            "I’ve definitely changed my driving style. With the regenerative braking, it pays to brake smoothly over a longer distance and then that way you’re capturing as much energy back as you can and that really does increase your miles per gallon or range depending on what you’re doing."<br>
          </span>
          <span class="author">Steve, <br> 2018 Chrysler Pacifica Hybrid Owner</span>
        </div>
      </div>

      <div id="stat-1" class="card card-basic">
        <h3 class="heading">The average American drives 16 miles to work each way<span id="range-3" class="asterisk">*</span></h3>
        <span class="subheading">With 32 miles of electric range<span id="ranget-0" class="asterisk">*</span>, <br> that’s pretty perfect.</span>
      </div>

      <div id="stat-2" class="card card-basic">
        <h3 class="heading">
          Chrysler Pacifica Hybrid has the best 7-passenger hybrid MPGe rating<span id="range-4" class="asterisk">*</span> <br>
        </h3>
        <span class="subheading">
          What is MPGe? Mile per gallon of gasoline-equivalent. That's how many miles your vehicle can go using a quantity of fuel with the same energy content as a gallon of gasoline.
        </span>
      </div>

      <div id="img-1" class="card card-img">
        <div class="img-container">
          <img src="../assets/img/range/testimonial-range.jpg" alt="2021 Chrysler Pacifica Hybrid - On mountain road.">
        </div>
      </div>

      <a id="cta-1" class="cta-bubble bubble-primary trackable" href="https://www.chrysler.com/pacifica/hybrid.html#find_my_range_copy_c" target="_blank" rel="noopener" @click="track('Click', 'In page nav', 'Range - Testimonial button')">
        <span>Explore My Range</span>
      </a>

      <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 1">
      <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 2">
      <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 3">

  </section>
</template>

<script>

export default {
  name: 'TestimonialsRange',
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, { 
        event_category: category,
        event_label: label,
      });
    }
  }
}
</script>