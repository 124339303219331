<template>
  <section ref="testimonials" class="testimonials">
      
    <div id="quote-1" class="card card-quote">
      <span class="quote">
        I’m OBSESSED with <br> my Chrysler Pacifica Hybrid.
      </span>
      <span class="author">Heidi, <br> 2019 Chrysler Pacifica Hybrid Owner</span>
    </div>

    <!-- <div id="stat-1" class="card card-basic">
      <span class="heading">Fill it up with 87 Octane</span>
      <span class="subheading">
        Worry not if you run out of charge <br>
        (Chrysler Pacifica Hybrid is the most fuel-efficient 7-passenger plug-in hybrid vehicle<span id="affordability-4" class="asterisk">*</span>)
      </span>
    </div> -->
    
    <!-- <div id="stat-2" class="card card-basic">
      <span class="heading">
        Chrysler Pacifica Hybrid has the best 7-passenger hybrid MPGe rating<span id="affordability-4" class="asterisk">*</span> <br>
      </span>
      <span class="subheading">
        What is MPGe? Mile per gallon of gasoline-equivalent. That's how many miles your vehicle can go using a quantity of fuel with the same engery content as a gallon of gasoline.
      </span>
    </div> -->
    
    <div id="stat-3" class="card card-basic">
      <h3 class="heading">Low maintenance</h3>
      <span class="subheading">Maintenance schedules for Chrysler Pacifica Hybrid and <br> Chrysler Pacifica Gas are very similar<span id="affordability-4" class="asterisk">*</span>.</span>
    </div>

    <!-- <div id="img-1" class="card card-img">
      <div class="img-container">
        <img src="../assets/img/affordability/testimonial-affordability.jpg" alt="2021 Chrysler Pacifica Hybrid - Snow in front of home.">
      </div>
    </div> -->
    <div id="img-2" class="card card-img">
      <div class="img-container">
        <img src="../assets/img/affordability/testimonial-affordability-customer.jpg" alt="2021 Chrysler Pacifica Hybrid - Snow in front of home.">
      </div>
      <div class="content">
         <span class="quote">"We’re going to get a lot of benefit from this purchase. We received the tax credit. Oil
         changes are based on miles on the engine, not on the battery. So, we even save on how often we get an oil
         change done." <br>
        </span>
        <span class="author">Angela,<br>2018 Chrysler Pacifica Hybrid Owner</span>
      </div>
    </div> 

    <a id="cta-1" class="cta-bubble bubble-primary trackable" href="https://apps.irs.gov/app/tax-withholding-estimator" target="_blank" rel="noopener"  @click="track('Click', 'In page nav', 'Affordability - Testimonial button')">
      <span>IRS Tax Calculator</span>
    </a>
    
    <a id="cta-2" class="cta-bubble bubble-success trackable" href="https://www.irs.gov/pub/irs-pdf/f8936.pdf" target="_blank" rel="noopener">
      <span class="w-100">Apply For Your Tax Credit</span>
    </a>
    
    <a id="cta-3" class="cta-bubble bubble-secondary trackable" href="https://www.chrysler.com/pacifica/incentives/hybrid-incentives.html"
      target="_blank" rel="noopener" @click="track('Click', 'In page nav', 'Affordability - Hybrid Incentive Finder')">
      <span class="w-100">Hybrid Incentive Finder</span>
    </a>
    <div id="video-1" class="card card-video">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe id="player1" class="embed-responsive-item" src="https://www.youtube.com/embed/jw1fdu05pm4?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="content">
        <h3 class="heading text-primary">“It’s such an amazing car. Its features just stood out. It was kind of a no-brainer.”</h3>
        <p>Heidi talks about why her hybrid is the perfect choice for her and her busy family, as well as the cost to own her 2019 Chrysler Pacifica Hybrid Limited.</p>
      </div>
    </div>

    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 1">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 2">
    <img class="accent" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image 3">

  </section>
</template>

<script>
export default {
  name: 'TestimonialsAffordability',
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  }
}
</script>