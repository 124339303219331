<template>
  <div v-if="!loading" id="affordability" class="bg-light page-wrapper active">

    <div class="hero hero-myth">
      <picture>
        <source media="(max-width: 767px)" :srcset="getHero(content.heroImages[2])">
        <source media="(min-width:768px) and (max-width: 1199px)" :srcset="getHero(content.heroImages[1])">
        <img :src="getHero(content.heroImages[0])" alt="Chrysler Pacifica Hybrid - Affordability Facts">
      </picture>

      
      <div class="title">
        <img :src="getIcon(content.iconLg)" alt="Title Icon">
        <h1 class="myth-title">{{ content.name }}</h1>
      </div>
    </div>

    <section class="intro py-5 mb-lg-5">
      <div class="container-fluid">
        <div class="row">
          <div class="fact col-lg-12 text-center">
            <span class="fact-label">Fact</span>
            <h2 class="fact-heading" v-html="content.pageHeadline">
            </h2>
          </div>
        </div>
      </div>
    </section> 

    <section id="affordability-animation" ref="animation" class="animation">
      <div class="container-fluid animation-inner" @mouseover="track('Scroll', 'On-site engagement', 'Affordability - Scroll')">
        <div class="scroller">
          <div class="vehicle-images" ref="vehicleImgs">
            <!-- <video ref="video" muted playsinline autoplay>
              <source src="../assets/video/pacifica-360-affordability-main.mp4" type="video/mp4">
              <p>Your browser doesn't support HTML5 video.</p>
            </video> -->
            <canvas ref="canvas"></canvas>
          </div>
          <div class="text-content">
            <div id="h1" class="heading-container">
              <h3 class="heading heading-1"> 
                Excellent <br class="d-none d-xl-block d-full-none"> Cost of Operation
                <span class="subheading subheading-1">
                  90% of Chrysler Pacifica Hybrid owners are <span class="font-bold">Extremely Satisfied</span> with the cost of operating their vehicle<span id="affordability-2" class="asterisk">*</span>
                </span>
              </h3>
            </div>
            <div id="h2" class="heading-container">
              <h3 class="heading heading-2">
                Federal Tax Credit<span id="affordability-0" class="asterisk">*</span>
                <span class="subheading subheading-2">
                   You could get money back for purchasing a hybrid
                </span>
              </h3>
            </div>
            <div id="h3" class="heading-container">
              <h3 class="heading heading-3">
                Many Current Offers <br class="d-none d-xl-block"> and Incentives<span id="affordability-1" class="asterisk">*</span>
                <span class="subheading subheading-3">
                  From Chrysler and Chrysler Capital<span id="affordability-6" class="asterisk">*</span>
                </span>
              </h3>
            </div>
            <div id="h4" class="heading-container">
              <h3 class="heading heading-4">
                On average, voltage <br class="d-none d-xl-block"> costs less than fuel<span id="affordability-3" class="asterisk">*</span>
                <span class="subheading subheading-4">
                  ($1.69 mpg less)
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <CalculatorAffordability />

    <PreFooter />

    <TestimonialsAffordability />
  </div>
</template>

<script> 
import axios from 'axios';
import JQuery from 'jquery';
import PreFooter from '@/components/PreFooter';
import CalculatorAffordability from '@/components/CalculatorAffordability';
import TestimonialsAffordability from '@/components/TestimonialsAffordability';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

let $ = JQuery;

export default {
  name: 'Affordability',
  components: { CalculatorAffordability, PreFooter, TestimonialsAffordability },
  data: () => {
    return {
      loading: true,
      content: null,
      tl: null
    }
  },
  metaInfo: {
    title: 'Affordability | Why Chrysler Pacifica Hybrid',
    meta: [
      { name: 'description', content: 'Chrysler Pacifica Hybrid is an efficient, affordable minivan!' },
      { name: 'keywords', content: 'Some, keywords, for, testing' },
      { name: 'og:description', content: 'Chrysler Pacifica Hybrid is an efficient, affordable minivan!' },
      { name: 'og:image', content: '/img/hero-lg.7cfdb654.jpg' }
    ]
  },
  mounted() {
    $('body').addClass('overflow-y-scroll');
    axios.get(`/data/app.json`).then(response => {
      this.content = response.data[0].facts[2];
      this.loading = false;
      this.$gtag.pageview(this.$route)
      $('body').removeClass('overflow-y-scroll');
    }).then(() => {

      this.$nextTick(function () {

        const canvas = this.$refs.canvas;
        const context = this.$refs.canvas.getContext("2d");
        const containerWidth = this.$refs.vehicleImgs.getBoundingClientRect().width;
        const images = []
        const vehicle = {
          frame: 0
        };
        const frameCount = 36; // amount of images in sequence

        canvas.width = containerWidth;
        canvas.height = canvas.width * 0.5625;

        // Render images to canvas
        function render() {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(images[vehicle.frame], 0, 0, canvas.width, canvas.height);
        }

        function currentFrame(index) {
          return `/vehicle-sequence/${index + 1}.png`
        }

        // Create canvas images for 360
        for (let i = 0; i < frameCount; i++) {
          const img = new Image();
          img.src = currentFrame(i);
          images.push(img);
        }

        // render initial image to canvas
        images[0].onload = render;

        // Set scroll animation
        this.tl = gsap.timeline({
          scrollTrigger: {
            trigger: this.$refs.animation,
            pin: true,
            start: "top 25%",
            end: "+=" + (window.innerHeight * 2),
            scrub: 0.05
          }
        });

        // Set scroll animation for canvas 360
        this.tl2 = gsap.to(vehicle, {
          frame: frameCount - 1,
          snap: "frame",
          scrollTrigger: {
            trigger: this.$refs.animation,
            start: "top 25%",
            end: "+=" + (window.innerHeight * 2),
            scrub: 0.5
          },
          onUpdate: render
        });


        this.tl
          .to('.scroller', {top: "25%"}) // scroll element to center of scene
          
          .to('#h1', {y: 0, opacity: 0.25, display: "none"}) // slide in first headline
          
          .to('#h2', {y: 0, opacity: 1, display: "block"}) // Show second headline

          .to('#h2', {y: 0, opacity: 0.25, display: "none"}) // hide second headline
          .to('#h3', {y: 0, opacity: 1, display: "block"}) // Show third headline

          .to('#h3', {y: 0, opacity: 0.25, display: "none"}) // hide third headline
          .to('#h4', {y: 0, opacity: 1, display: "block"}) // Show fourth headline


        // Refresh scrolltrigger to avoid issues with calculations while changing routes.
        setTimeout(function() {
          ScrollTrigger.refresh()
        }, 250);
      }); 
    })
      
  },
  methods: {
    getIcon(icon) {
      return icon ? require(`../assets/img/icons/${icon}`) : ''
    },
    getHero(image) {
      return image ? require(`../assets/img/${image}`) : ''
    },
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  },
  destroyed() {
    this.tl.kill();
  }
}
</script>