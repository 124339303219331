<template>
  <div>
    <!-- <section class="ctas bg-tertiary py-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="cta-group text-center">
              <a href="https://www.chrysler.com/bmo.html#/models/2021/pacifica_hybrid" target="_blank" rel="noopener" class="btn btn-secondary" @click="track('Click', 'On-site engagement', 'Build and Price')">Build &amp; Price</a>
              <a href="https://www.chrysler.com/lineup.sni.html" target="_blank" rel="noopener" class="btn btn-secondary" @click="track('Click', 'On-site engagement', 'Search Inventory')">Search Inventory</a>
              <a href="https://e-shop.chrysler.com/t1?adobe_mc=MCMID%3D91705648443268142939220958204698190478%7CMCORGID%3D3ECF483F53AB366E0A490D44%2540AdobeOrg%7CTS%3D1621362820&ott=start-buying-process&refrel=chrysler&vehicle_type=new" target="_blank" rel="noopener" class="btn btn-secondary" @click="track('Click', 'On-site engagement', 'Start Buying Process')">Start Buying Process</a>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="testimonial-intro bg-white py-4">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h5 class="h1 font-bold text-secondary">There's even more to the story</h5>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PreFooter',
   methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  }
}
</script>