<template>
  <section class="home-testimonial">
    <div class="section-lead">
      <div class="controls controls-testimonials"></div>
    </div>
    <div class="container-fluid">
      <div class="row px-3">
        <slick v-if="!loading" 
            class="slider d-flex"
            ref="slickTestimonialVideos"
            :options="slickOptions"
        >
        <div class="px-3">
          <div class="card card-horizontal">
            <div class="embed-responsive embed-responsive-16by9 video-container">
              <div class="poster" 
                :style="{'background-image': 'url(' + require('@/assets/img/testimonials/posters/poster-heidi.jpg') + ')'}" 
                :class="{'hidden': posterHidden === 1}" 
                @click="hidePoster(1, 'testimonialVideo2'); track('Click', 'Homepage Videos', 'Heidi Testimonial')">
                <span class="play-icon"></span>
                <span class="video-title"></span>
              </div>
              <youtube class="embed-responsive-item" :video-id="'jw1fdu05pm4'" ref="testimonialVideo2"></youtube>
            </div>
            <div class="content">
              
              <div class="author">
                Heidi, <br>
                <span class="note">Owner (2019 Chrysler Pacifica Hybrid)</span>
              </div>
              <div class="quote">
                I had another minivan for a decade and thought I wanted the new model, but we test drove the Chrysler Pacifica Hybrid and we couldn't believe how much we loved it!
              </div>

            </div>
          </div>
        </div>

        <div class="px-3">
          <div class="card card-horizontal">
            <div class="embed-responsive embed-responsive-16by9 video-container">
              <div class="poster" 
                :style="{'background-image': 'url(' + require('@/assets/img/testimonials/posters/poster-howto.jpg') + ')'}" 
                :class="{'hidden': posterHidden === 0}" 
                @click="hidePoster(0, 'testimonialVideo1'); track('Click', 'Homepage Videos', 'How to Charge')">
                <span class="play-icon"></span>
                <span class="video-title"></span>
              </div>
              <youtube class="embed-responsive-item" :video-id="'MpgDwDlt7j8'" ref="testimonialVideo1"></youtube>
            </div>
            <div class="content">
              
              <div class="author">
                If you can plug in your toaster, you can plug in this car.
              </div>
              <div class="quote">
                2019 Chrysler Pacifica Hybrid owner Heidi shows how easy it is to plug in the vehicle and monitor its charging progress.
              </div>
            </div>
          </div>
        </div>

        <div class="px-3">
          <div class="card card-horizontal">
            <div class="embed-responsive embed-responsive-16by9 video-container">
              <div class="poster" 
                :style="{'background-image': 'url(' + require('@/assets/img/testimonials/posters/dave-angela-test.jpg') + ')'}" 
                :class="{'hidden': posterHidden === 4}" 
                @click="hidePoster(4, 'testimonialVideo5'); track('Click', 'Homepage Videos', 'Angela Crosser Testimonial')">
                <span class="play-icon"></span>
                <span class="video-title"></span>
              </div>
              <youtube class="embed-responsive-item" :video-id="'lGXhc9ntRJY'" ref="testimonialVideo5"></youtube>
            </div>
            <div class="content">
              
              <div class="author pr-0">
                Dave and Angela, <br>
                <span class="note">Owners (2018 Chrysler Pacifica Hybrid)</span>
              </div>
              <div class="quote">
                 It makes her feel safe, it makes me feel fun.
              </div>

            </div>
          </div>
        </div>

        <div class="px-3">
          <div class="card card-horizontal">
            <div class="embed-responsive embed-responsive-16by9 video-container">
              <div class="poster" 
                :style="{'background-image': 'url(' + require('@/assets/img/testimonials/posters/crosser-dave-test.jpg') + ')'}" 
                :class="{'hidden': posterHidden === 2}" 
                @click="hidePoster(2, 'testimonialVideo3'); track('Click', 'Homepage Videos', 'Dave Crosser Testimonial')">
                <span class="play-icon"></span>
                <span class="video-title"></span>
              </div>
              <youtube class="embed-responsive-item" :video-id="'aWlye_6a5Kw'" ref="testimonialVideo3"></youtube>
            </div>
            <div class="content">
              
              <div class="author">
                You can set it up to be customized however it works best for your situation.
              </div>
              <div class="quote">
                 2018 Chrysler Pacifica Hybrid owner Dave tells how to schedule charging with a Level II charger.
              </div>

            </div>
          </div>
        </div>

        <div class="px-3">
          <div class="card card-horizontal">
            <div class="embed-responsive embed-responsive-16by9 video-container">
              <div class="poster" 
                :style="{'background-image': 'url(' + require('@/assets/img/testimonials/posters/crosser-angela-test.jpg') + ')'}" 
                :class="{'hidden': posterHidden === 3}" 
                @click="hidePoster(3, 'testimonialVideo4'); track('Click', 'Homepage Videos', 'Angela Crosser Testimonial')">
                <span class="play-icon"></span>
                <span class="video-title"></span>
              </div>
              <youtube class="embed-responsive-item" :video-id="'phI4tKnYYW0'" ref="testimonialVideo4"></youtube>
            </div>
            <div class="content">
              
              <div class="author">
                I can change the configuration of the seats so many different ways.
              </div>
              <div class="quote">
                 2018 Chrysler Pacifica Hybrid owner Angela demonstrates how to use the vehicle's third-row Stow 'N Go feature.
              </div>

            </div>
          </div>
        </div>

        </slick>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Slick from 'vue-slick';
export default {
  name: 'TestimonialsHome',
  components: { Slick },
  data() {
    return {
      loading: true,
      posterHidden: null,
      slickOptions: null,
    }
  },
  created() {
      axios
        .get(`/data/homeTestemonial.json`)
        .then((response) => {
          this.homeTestemonials = response.data.homeStemonials;
          console.log(this.homeTestemonials)
        }).then(() => {
          this.slickOptions = {
              dots: false,
              infinite: true,
              speed: 300,
              slidesToShow: 2,
              arrows: true,
              appendArrows: '.controls-testimonials',
              responsive: [
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    infinite: true,
                  }
                },
              ]
          }
        }).then(() => {
          this.loading = false; 
          console.log(this.$route.name)
        });
    },
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
    hidePoster(id, ref) {
      this.posterHidden = id;
      this.$refs[ref].player.playVideo();
    }
  }
}
</script>