import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Range from '../views/Range.vue'
import Charging from '../views/Charging.vue'
import Affordability from '../views/Affordability.vue'
import BatteryLife from '../views/BatteryLife.vue'
import Technology from '../views/Technology.vue'
import Practicality from '../views/Practicality.vue'
import Faq from '../views/Faq.vue'
import Site_Map from '../views/Site_Map.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/range/',
    name: 'Range',
    component: Range
  },
  {
    path: '/charging/',
    name: 'Charging',
    component: Charging
  },
  {
    path: '/affordability/',
    name: 'Affordability',
    component: Affordability
  },
  {
    path: '/battery-life/',
    name: 'BatteryLife',
    component: BatteryLife
  },
  {
    path: '/technology/',
    name: 'Technology',
    component: Technology
  },
  {
    path: '/practicality/',
    name: 'Practicality',
    component: Practicality
  },
  {
    path: '/faq/',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/site-map/',
    name: 'Site_Map',
    component: Site_Map
  },
  {
    path: '/404/',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '*',
    redirect: '/404/' 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
