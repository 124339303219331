<template>
  <div v-if="!loading" id="technology" class="bg-light page-wrapper active">

    <div class="hero hero-myth">
      <picture>
        <source media="(max-width: 767px)" :srcset="getHero(content.heroImages[2])">
        <source media="(min-width:768px) and (max-width: 1199px)" :srcset="getHero(content.heroImages[1])">
        <img :src="getHero(content.heroImages[0])" alt="Chrysler Pacifica Hybrid - Affordability Facts">
      </picture>

      
      <div class="title">
        <img :src="getIcon(content.iconLg)" alt="Title Icon">
        <h1 class="myth-title">{{ content.name }}</h1>
      </div>
    </div>

    <section class="intro py-5 mb-lg-5">
      <div class="container-fluid">
        <div class="row">
          <div class="fact col-lg-12 text-center">
            <span class="fact-label">Fact</span>
            <h2 class="fact-heading" v-html="content.pageHeadline">
            </h2>
          </div>
        </div>
      </div>
    </section> 

    <section id="technology-animation" ref="animation" class="animation">
      <div class="container-fluid animation-inner" @mouseover="track('Scroll', 'On-site engagement', 'Technology - Scroll')">
        <div class="facts-scroller">
          <div class="stats">
            <span class="thin-headline d-block">Chrysler Pacifica Hybrid</span>
            <span class="main-headline d-block">It's as easy as...</span>
            <div class="stat-container">
              <span class="stat stat-1">Pushing a button <br> to start the car</span>
              <span class="stat stat-2">Pressing play to start a movie on the available Uconnect<sup>&reg;</sup> Theater System</span>
              <span class="stat stat-3">Looking at the cluster <br> to see if you’re using gas or electric mode</span>
              <span class="stat stat-4">Plugging it in <br> to charge the battery</span>
            </div>
          </div>
        </div>
      </div>

      <div id="battery" class="graphic" data-speed="1.05">
        <img src="../assets/img/technology/battery.png" alt="Battery Image">
      </div>
      <div id="braking" class="graphic" data-speed="1.10">
        <img src="../assets/img/technology/braking.png" alt="Braking Image">
      </div>
      <div id="battery-lithium" class="graphic" data-speed="0.95">
        <img src="../assets/img/technology/battery-lithium.png" alt="Battery Lithium Image">
      </div>
      <div id="battery-lithium-2" class="graphic" data-speed="0.95">
        <img src="../assets/img/technology/battery-lithium.png" alt="Battery Lithium Image">
      </div>
      <div id="battery-lithium-3" class="graphic" data-speed="0.95">
        <img src="../assets/img/technology/battery-lithium.png" alt="Battery Lithium Image">
      </div>
      <div id="chat-bubble" class="graphic" data-speed="1.25">
        <img src="../assets/img/technology/battery-lithium.png" alt="Battery Lithium Image">
      </div>
      <div id="engine-btn" class="graphic" data-speed="1.20">
        <img src="../assets/img/technology/engine-btn.png" alt="Engine Image">
      </div>
      <div id="play" class="graphic" data-speed="1">
        <img src="../assets/img/technology/play.png" alt="Play Image">
      </div>
      <div id="outlet" class="graphic" data-speed="0.85">
        <img src="../assets/img/technology/outlet.png" alt="Outlet Image">
      </div>
      <div id="outlet-240" class="graphic" data-speed="0.75">
        <img src="../assets/img/technology/outlet-240.png" alt="Outlet Image">
      </div>
      <div id="pump" class="graphic" data-speed="1.15">
        <img src="../assets/img/technology/pump.png" alt="Pump Image">
      </div>
      <div id="battery-2" class="graphic" data-speed="1.05">
        <img src="../assets/img/technology/battery.png" alt="Battery Image">
      </div>
      <div id="battery-3" class="graphic" data-speed="1.05">
        <img src="../assets/img/technology/battery.png" alt="Battery Image">
      </div>
      <div id="braking-2" class="graphic" data-speed="1.10">
        <img src="../assets/img/technology/braking.png" alt="Braking Image">
      </div>
      <div id="chat-bubble-2" class="graphic" data-speed="1.25">
        <img src="../assets/img/technology/braking.png" alt="Braking Image">
      </div>
      <div id="engine-btn-2" class="graphic" data-speed="1.20">
        <img src="../assets/img/technology/engine-btn.png" alt="Engine Image">
      </div>
      <div id="play-2" class="graphic" data-speed="1">
        <img src="../assets/img/technology/play.png" alt="Play Image">
      </div>
      <div id="outlet-2" class="graphic" data-speed="0.85">
        <img src="../assets/img/technology/outlet.png" alt="Outlet Image">
      </div>
      <div id="outlet-240-2" class="graphic" data-speed="0.75">
        <img src="../assets/img/technology/outlet-240.png" alt="Outlet Image">
      </div>
      <div id="pump-2" class="graphic" data-speed="1.15">
        <img src="../assets/img/technology/pump.png" alt="Pump Image">
      </div>
      <div id="pump-3" class="graphic" data-speed="1.15">
        <img src="../assets/img/technology/pump.png" alt="Pump Image">
      </div>
    </section>

    <PreFooter />  

    <TestimonialsTechnology />

  </div>
</template>

<script>
import axios from 'axios';
import PreFooter from '@/components/PreFooter';
import TestimonialsTechnology from '@/components/TestimonialsTechnology';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Technology',
  components: { PreFooter, TestimonialsTechnology },
  data: () => {
    return {
      loading: true,
      tl: null
    }
  },
  metaInfo: {
    title: 'Technology | Why Chrysler Pacifica Hybrid',
    meta: [
      { name: 'description', content: 'Chrysler Pacifica Hybrid is easy to drive and maintain.' },
      { name: 'keywords', content: 'Some, keywords, for, testing' },
      { name: 'og:description', content: 'Chrysler Pacifica Hybrid is easy to drive and maintain.' },
      { name: 'og:image', content: '/img/hero-lg.aceb014b.jpg' },
      { name: 'og:title', content: 'Technology | Why Chrysler Pacifica Hybrid' },
      { name: 'twitter:card', content: 'summary_large_image' }
    ]
  },
  mounted() {
    axios.get(`/data/app.json`).then(response => {
      this.content = response.data[0].facts[4];
      this.loading = false
      this.$gtag.pageview(this.$route)
    }).then(() => {
       this.$nextTick(function () {

        // Set animation
        this.tl = gsap.timeline({
          scrollTrigger: {
            trigger: this.$refs.animation,
            pin: true,
            start: "top 0%",
            end: "+=" + (window.innerHeight * 2),
            scrub: 0.5
          }
        });

        gsap.utils.toArray(".graphic").forEach(graphic => {
          gsap.timeline({
            scrollTrigger: {
              trigger: this.$refs.animation,
              ease: "expo.inOut",
              start: "top 0%",
              end: '+=12000',
              scrub: 0.1
            }
        })
          .to(graphic, {y: "-350vh", ease: "power3", duration: 2500}, 0)
        });

        this.tl
          .to('.facts-scroller', {top: "50%", duration: 5}) // scroll element to center of scene
          
          .to('.stat-1', {y: 0, opacity: 1, display: 'block', duration: 5}) // slide in first headline
          
          .to('.stat-1', {opacity: 0, display: 'none', duration: 0, delay: 10}) // slide out first headline
          .to('.stat-2', {y: 0, opacity: 1, display: 'block', duration: 5}) // slide in second headline
          
          .to('.stat-2', {opacity: 0, display: 'none', duration: 0, delay: 10}) // slide out second headline
          .to('.stat-3', {y: 0, opacity: 1, display: 'block', duration: 5}) // slide in third headline
          
          .to('.stat-3', {opacity: 0, display: 'none', duration: 1, delay: 10}) // slide out third headline
          .to('.stat-4', {y: 0, opacity: 1, display: 'block', duration: 5}) // slide in fourth headline


        // Refresh scrolltrigger to avoid issues with calculations while changing routes.
        setTimeout(function() {
          ScrollTrigger.refresh()
        }, 250)
      });  
    
    })
    
   
      
  },
  methods: {
    getIcon(icon) {
      return icon ? require(`../assets/img/icons/${icon}`) : ''
    },
    getHero(image) {
      return image ? require(`../assets/img/${image}`) : ''
    },
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    }
  },
  destroyed() {
    // this.tl.kill();
    // this.tl2.kill();
  }
}
</script>