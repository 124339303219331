
<template>
  <div v-if="!loading" class="modal modal-primary fade" id="trademarks-modal" ref="trademarksModal" tabindex="-1" aria-labelledby="trademarks-modal-heading" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header pb-3">
          <button class="btn btn-close btn-icon" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
          <h5 class="modal-title condensed" id="trademarks-modal-heading">Legal, Safety and Trademark Information</h5>
          <img class="mh-leaves" src="../assets/img/bg-repeat-full.png" alt="Background Leaf Image">
        </div>
        <div class="modal-body disclaimers">
          <p v-for="(item, index) in items" :key="`trademark-${index}`">{{ item }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'TrademarksModal',
  data: () => {
    return {
      items: null,
      loading: true
    }
  },
  mounted() {
    axios.get(`/data/trademarks.json`).then(response => {
      this.items = response.data[0].trademarks;
      this.loading = false;
    })
  }
}
</script>