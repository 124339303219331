<template>
  <div v-if="!loading" id="charging" class="bg-light page-wrapper active">
    
    <div class="hero hero-myth">
      <picture>
        <source media="(max-width: 767px)" :srcset="getHero(content.heroImages[2])">
        <source media="(min-width:768px) and (max-width: 1199px)" :srcset="getHero(content.heroImages[1])">
        <img :src="getHero(content.heroImages[0])" alt="Chrysler Pacifica Hybrid - Charging Facts">
      </picture>

      <div class="title">
          <img :src="getIcon(content.iconLg)" alt="Title Icon">
          <h1 class="myth-title">{{ content.name }}</h1>
      </div>
    </div>

    <section class="intro py-5 mb-lg-5">
      <div class="container-fluid">
        <div class="row">
          <div class="fact col-lg-12 text-center">
            <span class="fact-label">Fact</span>
            <h2 class="fact-heading">
              {{ content.pageHeadline }}
            </h2>
          </div>
        </div>
      </div>
    </section> 

    <section id="charging-animation" ref="animation" class="animation">
      <div class="container-fluid animation-inner" @mouseover="track('Scroll', 'On-site engagement', 'Charging - Scroll')">
        <div class="feature">
          <div class="instructions">
            <span>Hover over the graphic to reveal more information</span>
          </div>
          <div id="plug-110" class="item active trackable">
            <div class="header"></div>
            <div class="graphic">
              <img src="../assets/img/charging/plug-110.svg" alt="Plug 110 Image" />
            </div>
            <div class="info first">
              <p class="heading mb-0">120V outlet</p>
              <p class="desc">Charge overnight (14 hours)</p>
              <p class="footer mb-0">
                Standard<span class="font-bold text-primary"> Level I </span> charger included with Chrysler Pacifica Hybrid
              </p>
            </div>
          </div>
          <div id="plug-240" class="item active trackable">
            <div class="header"></div>
            <div class="graphic">
              <img src="../assets/img/charging/plug-220.svg" alt="Plug 220 Image" />
            </div>
            <div class="info second">
              <p class="heading mb-0">240V outlet with Level II charger<span id="charging-0" class="asterisk" style="pointer-events: auto;">*</span></p>
              <p class="desc">Charge in as little as 2 hours</p>
              <p class="footer mb-0">
                <a
                  href="https://store.mopar.com/p-mopar-branded-plug-in-home-ev-charger-level-2-hcs40pm"
                  target="_blank"
                  rel="noopener" 
                  style="pointer-events: auto;"
                  >Purchase a Mopar Level II Charger</a
                >
              </p>
            </div>
          </div>
          <div id="regen-brake" class="item active trackable">
            <div class="header"></div>
            <div class="graphic">
              <img src="../assets/img/charging/regen-brake-blue.svg" alt="Regen Brake Image" />
            </div>
            <div class="info third">
              <p class="heading mb-0">Regenerative Braking</p>
              <p class="desc">Charge on the go without a charger.</p>
              <p class="footer mb-0">
                The battery self charges via regenerative braking, which captures energy from the brakes when they are applied.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <PreFooter />

    <TestimonialsCharging />
  </div>
</template>

<script>
import axios from 'axios';
import JQuery from 'jquery';
import PreFooter from "@/components/PreFooter";
import TestimonialsCharging from "@/components/TestimonialsCharging";
let $ = JQuery;

export default {
  name: "Charging",
  components: { PreFooter, TestimonialsCharging },
  data: () => {
    return {
      content: null,
      loading: true
    };
  },
  metaInfo: {
    title: 'Charging | Why Chrysler Pacifica Hybrid',
    meta: [
      { name: 'description', content: 'Think you’ll be tied to a plug in the Chrysler Pacifica Hybrid? Think again.' },
      { name: 'keywords', content: 'Some, keywords, for, testing' },
      { name: 'og:description', content: 'Think you’ll be tied to a plug in the Chrysler Pacifica Hybrid? Think again.' },
      { name: 'og:image', content: '/img/hero-lg.2b79b692.jpg' }
    ]
  },
  mounted() {
    $('body').addClass('overflow-y-scroll');
    axios.get(`/data/app.json`).then(response => {
      this.content = response.data[0].facts[1];
      this.loading = false;
      this.$gtag.pageview(this.$route);
      $('body').removeClass('overflow-y-scroll');
    });
  },
  methods: {
    track(action, category, label) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },
    getIcon(icon) {
      return icon ? require(`../assets/img/icons/${icon}`) : ''
    },
    getHero(image) {
      return image ? require(`../assets/img/${image}`) : ''
    }
  },
  destroyed() {
  },
};
</script>