<template>
  <div class="overlay-nav mobile d-lg-none" :class="isActive ? 'active' : null">
    <div class="overlay-inner">
      <!-- <a v-for="item in items" :key="item.id" class="nav-item" :href="item.href" :target="item.external ? '_blank' : null" @click="track('Click', 'Navigation Fact Finder', item.name)">
        <i class="fa" :class="!item.icon ? 'invisible fa-envelope' : `fa-${item.icon}`"></i> {{ item.name }}
      </a> -->
      <a v-for="item in items" :key="item.id" class="nav-item btn btn-icon text-center" :href="item.href" :target="item.external ? '_blank' : null" 
        :class="item.modal ? 'btn-success' : 'btn-primary'" 
        :data-toggle="item.modal ? 'modal' : null" 
        :data-target="item.modal ? item.href : null" 
        @click="emitClose(); track('Click', item.eventCategoryHeader, item.eventLabel)"
        onclick="javascript:$zoho.salesiq.floatwindow.visible('hide');">
        <i class="fa text-white" :class="`fa-${item.icon}`"></i> {{ item.name }}
      </a>
    </div>
  </div>
</template>

<script>
//Overlay nav for mobile
export default {
  name: 'OverlayMobile',
  props: {
    items: null,
    isActive: Boolean
  },
  methods: {
    emitClose() {
      this.$emit('active', false);
    },
    track(action, category, label) {
      this.$gtag.event(action, {
      'event_category': category,
      'event_label': label,
      });
    }
  }
}
</script>